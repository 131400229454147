import IdentityService from "./identity/IdentityService"
import { MemoryIdentityService } from "./identity/MemoryIdentityService"
import { ApiIdentityService } from "./identity/ApiIdentityService"
import DataService from "./data/DataService"
import { ApiDataService } from "./data/ApiDataService"
import { MemoryDataService } from "./data/MemoryDataService"

class ServiceProvider {
    public readonly identityService: IdentityService = (() => {
        if (process.env.REACT_APP_IDENTITY_SERVICE === "memory") {
            return new MemoryIdentityService(process.env.REACT_APP_IDENTITY_MEMORY_PREFILL === "yes")
        } else if (process.env.REACT_APP_IDENTITY_SERVICE === "api") {
            return new ApiIdentityService(process.env.REACT_APP_IDENTITY_API_URL)
        } else {
            throw new Error("Invalid identity service")
        }
    })()

    public readonly dataService: DataService = (() => {
        if (process.env.REACT_APP_DATA_SERVICE === "memory") {
            return new MemoryDataService(process.env.REACT_APP_DATA_MEMORY_PREFILL === "yes")
        } else if (process.env.REACT_APP_DATA_SERVICE === "api") {
            return new ApiDataService(process.env.REACT_APP_DATA_API_URL)
        } else {
            throw new Error("Invalid identity service")
        }
    })()
}

export default new ServiceProvider()
