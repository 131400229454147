import React, { Dispatch, MouseEvent, ReactNode, useEffect } from "react"
import SearchBox, { SearchBoxProps } from "./SearchBox"
import { DataServiceContext } from "../service/data/DataService"
import { FieldValues } from "react-hook-form/dist/types"
import saveAs from "file-saver"
import { ActionButton, ActionButtonProps } from "./ActionButton"
import { AppContext, useAppContext } from "../App"
import Session from "../model/Session"
import FullScreenLoader from "./Loading"

const DEFAULT_PAGE = 1
const DEFAULT_SEARCH = ""
const EMPTY_ARRAY = []
const TYPE_OBJECT = "object"
const ERROR_UNKNOWN_SERVER = "Unknown error occurred on the server"
const ERROR_BAD_DATA_FORMAT = "Unexpected data format received from server"
const ERROR_HTTP_STATUS = {}

enum PaginatedListState {
    Uninitialised,
    Loading,
    Loaded
}

type SelectionGlobalState = "all" | "partial" | "none" | "noRecords"
type ViewType = "grid" | "list"

interface SelectionCallbacks {
    readonly state: () => SelectionGlobalState
    readonly has: (key: string | number) => boolean
    readonly add: (key: string | number) => void
    readonly addAll: () => void
    readonly remove: (key: string | number) => void
    readonly removeAll: () => void
    readonly count: () => number
    readonly values: () => DataSourceRecordSet
}

interface SelectionState {
    readonly enabled: boolean
    readonly state: SelectionGlobalState
    readonly items: Set<string | number> | undefined
}

export interface Fetcher<TFieldValues extends FieldValues = FieldValues> {
    fetch(search: TFieldValues, context: DataServiceContext): Promise<FetchResponse>
}

export type FetchResponse<TFieldValues extends FieldValues = FieldValues> = {
    data?: FetchDataResponse
    search?: TFieldValues
    error?: FetchErrorResponse
}

export type FetchDataResponse = {
    columns?: Column[]
    records: DataSourceRecordSet
}

export type FetchErrorResponse = {
    message: string
}

export type Column = {
    key: string | number
    mode?: "grid" | "list"
    type?: "select"
    template?: "auto" | "max-content"
    title: ReactNode
}

export type DataSourceResult = {
    columns?: Column[]
    records?: DataSourceRecordSet
    totalRecords: number
    aborted?: boolean
    error?: string
}

export type DataSourceRecord = {
    key: string | number
    values: DataSourceValue[]
    onClick?: (event: MouseEvent) => void
    source?: unknown
}

export type DataSourceRecordSet = DataSourceRecord[]

export type DataSourceValue = ReactNode

export interface DataSource<TFieldValues extends FieldValues = FieldValues> {
    load(search: TFieldValues): Promise<DataSourceResult>
    abortLoad(): void
    filter(search: TFieldValues): Promise<DataSourceResult>
    abortFilter(): void
    canAbort(): boolean
}

class HttpFetcher<TFieldValues extends FieldValues = FieldValues> implements Fetcher<TFieldValues> {
    public readonly url: string

    constructor(url: string) {
        this.url = url
    }

    async fetch(search: TFieldValues, context: DataServiceContext): Promise<FetchResponse> {
        const response = await fetch(this.url, { signal: context.signal })

        try {
            return await response.json()
        } catch (e) {
            return {
                error: response.status in ERROR_HTTP_STATUS ? ERROR_HTTP_STATUS[response.status] : ERROR_BAD_DATA_FORMAT
            }
        }
    }
}

export abstract class RecordFetcher<TRecord, TFieldValues extends FieldValues = FieldValues>
    implements Fetcher<TFieldValues>
{
    async fetch(search: TFieldValues, context: DataServiceContext): Promise<FetchResponse> {
        try {
            const records: TRecord[] = await this.execute(search, context)
            const process = this.process.bind(this)
            return { search: search, data: { records: this.filter(records.map(process), search) } }
        } catch (e) {
            return { error: e.message }
        }
    }

    protected abstract execute(search: TFieldValues, context: DataServiceContext): Promise<TRecord[]>

    protected abstract process(record: TRecord): DataSourceRecord

    protected filter(records: DataSourceRecordSet, search?: TFieldValues): DataSourceRecordSet {
        const query = typeof search.q === "string" ? search.q : undefined

        if (query?.length > 0 && records?.length > 0) {
            const lcSearch = query.toLowerCase()

            records = records.filter((record) => {
                const values = record.values
                for (let i = 0, len = values.length; i < len; ++i) {
                    const item = values[i]
                    const isString = typeof item === "string"
                    if (isString && item.toLowerCase().includes(lcSearch)) return true
                }

                return false
            })
        }

        return records
    }

    public createDataSource(session: Session): FetchDataSource {
        return new FetchDataSource(this, session)
    }
}

export class StaticDataSource implements DataSource {
    public records: DataSourceRecordSet
    private readonly columns?: Column[]

    constructor(records: DataSourceRecordSet, columns?: Column[]) {
        this.records = records
        this.columns = columns
    }

    load(): Promise<DataSourceResult> {
        return Promise.resolve({ records: this.records, columns: this.columns, totalRecords: this.records.length })
    }

    filter(): Promise<DataSourceResult> {
        return this.load()
    }

    canAbort(): boolean {
        return false
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    abortFilter(): void {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    abortLoad(): void {}
}

export class FetchDataSource<TFieldValues extends FieldValues = FieldValues> implements DataSource<TFieldValues> {
    protected lastSearch?: TFieldValues
    private context?: DataServiceContext
    private aborted = false
    private readonly fetcher: Fetcher
    private readonly session: Session

    constructor(source: string | Fetcher, session: Session) {
        this.fetcher = typeof source === "string" ? new HttpFetcher(source) : source
        this.session = session
    }

    canAbort(): boolean {
        return this.context !== undefined
    }

    abortLoad(): void {
        this.abort()
    }

    abortFilter(): void {
        this.abort()
    }

    abort(): void {
        this.aborted = true
        this.context?.controller.abort()
    }

    async load(search: TFieldValues): Promise<DataSourceResult> {
        const result: DataSourceResult = {
            columns: undefined,
            records: EMPTY_ARRAY,
            totalRecords: 0,
            error: undefined
        }

        const context = new DataServiceContext(this.session)

        try {
            this.lastSearch = { ...search }
            this.context = context
            this.aborted = false

            await this.fetcher.fetch(search, context).then(async (response) => {
                const bodyIsObject = typeof response === TYPE_OBJECT
                const bodyHasData = bodyIsObject && response.data
                const bodyHasError = bodyIsObject && response.error
                let processed = false
                if (bodyHasData) {
                    const data = response.data

                    if (Array.isArray(data)) {
                        result.records = data
                        result.totalRecords = data.length
                        processed = true
                    } else if (typeof data === TYPE_OBJECT) {
                        if (data.columns) {
                            result.columns = data.columns
                        }

                        if (data.records) {
                            result.records = data.records
                            result.totalRecords = result.records.length
                            processed = true
                        }
                    }
                } else if (bodyHasError) {
                    const error = response.error
                    result.error = error.message ?? ERROR_UNKNOWN_SERVER
                    processed = true
                }

                if (!processed) {
                    result.error = ERROR_BAD_DATA_FORMAT
                }
            })
        } catch (e) {
            result.aborted = this.context !== context || this.aborted
            result.error = e.message
        }

        return result
    }

    async filter(search: TFieldValues): Promise<DataSourceResult> {
        return await this.load(search)
    }
}

export class PaginatedListContext<TFieldValues extends FieldValues = FieldValues> {
    private _appContext: AppContext
    private _currentPage: number
    private _maxPage: number
    private _search: string
    private _filter: TFieldValues | undefined
    private _recordsPerPage: number
    private _columns: Column[]
    private _unprocessedColumns: Column[]
    private _records: DataSourceRecordSet = EMPTY_ARRAY
    private _selection: SelectionState
    private _mode: "grid" | "list"
    private _lastDataSource: string | DataSource

    private setModeState: Dispatch<React.SetStateAction<"grid" | "list">>
    private setSearchState: Dispatch<React.SetStateAction<string>>
    private setFilterState: Dispatch<React.SetStateAction<TFieldValues | undefined>>
    private setColumnState: Dispatch<React.SetStateAction<Column[]>>
    private setRecordState: Dispatch<React.SetStateAction<DataSourceRecordSet>>
    private setCurrentPageState: Dispatch<React.SetStateAction<number>>
    private setRecordsPerPageState: Dispatch<React.SetStateAction<number>>
    private setMaxPageState: Dispatch<React.SetStateAction<number>>
    private setSelectionState: Dispatch<React.SetStateAction<SelectionState>>

    private csvFormatter: (record: DataSourceRecord) => (number | string)[]
    private state: PaginatedListState = PaginatedListState.Uninitialised
    private dataSource: DataSource
    private pendingRefresh = false
    private pendingFilter = false
    appContext: any
    dataServiceContext: DataServiceContext

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    static use<TFieldFormValues extends FieldValues = FieldValues>(
        props: PaginatedListProps<TFieldFormValues>
    ): PaginatedListContext {
        const cacheModeVal = (localStorage.getItem("viewMode") as ViewType) || "grid"
        const initMode =
            typeof props.mode === "string"
                ? props.mode
                : typeof props.mode === "object"
                ? cacheModeVal
                    ? cacheModeVal
                    : props.mode.initial
                : "list"
        const initRecordsPerPage = (props.recordsPerPage ?? 0) < 0 ? 0 : props.recordsPerPage ?? 0

        const appContext = useAppContext()

        // Required state variables
        const [currentPage, setCurrentPage] = React.useState<number>(DEFAULT_PAGE)
        const [recordsPerPage, setRecordsPerPage] = React.useState<number>(initRecordsPerPage)
        const [maxPage, setMaxPage] = React.useState<number>(0)
        const [columns, setColumns] = React.useState<Column[]>(
            PaginatedListContext.filterColumns(props.columns, initMode)
        )
        const [records, setRecords] = React.useState<DataSourceRecordSet>(EMPTY_ARRAY)
        const [selection, setSelection] = React.useState<SelectionState>({
            state: "noRecords",
            items: undefined,
            enabled: true
        })
        const [search, setSearch] = React.useState<string>(DEFAULT_SEARCH)
        const [filter, setFilter] = React.useState<TFieldFormValues | undefined>(props.filter)
        const [mode, setMode] = React.useState<"list" | "grid">(initMode)

        // Set up reference to context
        const ref = React.useRef<PaginatedListContext<TFieldFormValues>>()
        let context = ref.current

        // Check to see if we need to initialise a new context
        if (!ref.current) {
            ref.current = context = new PaginatedListContext<TFieldFormValues>()
            context.setCurrentPageState = setCurrentPage
            context.setMaxPageState = setMaxPage
            context.setSearchState = setSearch
            context.setFilterState = setFilter
            context.setRecordState = setRecords
            context.setColumnState = setColumns
            context.setRecordsPerPageState = setRecordsPerPage
            context.setSelectionState = setSelection
            context.setModeState = setMode

            context.csvFormatter = props.csvConverter
        }

        const hasNewDataSource = props.dataSource !== context._lastDataSource
        if (hasNewDataSource) {
            context._lastDataSource = props.dataSource
            context.dataSource = props.dataSource
        }

        context._appContext = appContext
        context._mode = mode
        context._currentPage = currentPage
        context._recordsPerPage = recordsPerPage
        context._maxPage = maxPage
        context._search = search
        context._filter = filter
        context._columns = columns
        context._unprocessedColumns = props.columns ?? []
        context._records = records
        context._selection = selection
        if (context.state === PaginatedListState.Uninitialised) {
            setTimeout(() => {
                context.initialise()
            }, 1000)
        } else if (hasNewDataSource) {
            context.refresh()
        }

        // Return instance
        return context
    }

    private static filterColumns(columns: Column[], mode: "grid" | "list"): Column[] {
        const len = columns?.length ?? 0
        const result = []

        for (let i = 0; i < len; ++i) {
            const column = columns[i]
            if ((column.mode ?? mode) !== mode) continue
            result.push(column)
        }

        return result
    }

    public set search(val: string) {
        this._search = val
        this.setSearchState(val)
        this.doFilter()
    }

    public readonly setFilter = (data: TFieldValues) => {
        this.filter = data
    }

    public set filter(val: TFieldValues | undefined) {
        this._filter = val
        this.setFilterState(val)
        this.doFilter()
    }

    public get filter(): TFieldValues {
        return this._search === undefined ? { ...this._filter } : { q: this._search, ...this._filter }
    }

    private doFilter() {
        if (this.state === PaginatedListState.Loaded) {
            this.setState(PaginatedListState.Loading)
            this.dataSource.filter(this.filter).then(this.processDataSourceResult)
        } else if (this.state == PaginatedListState.Loading) {
            this.pendingFilter = true
            this.dataSource.abortFilter()
        }
    }

    public readonly selection: SelectionCallbacks = {
        state: () => this._selection.state,
        add: (key: string | number) => {
            if (this._selection.enabled) {
                const selected = new Set<number | string>(this._selection.items)
                selected.add(key)
                this.updateSelection(selected)
            }
        },
        addAll: () => {
            if (this._selection.enabled) {
                const records = this.records
                const len = records.length
                const selected = new Set<number | string>(this._selection.items)

                for (let i = 0; i < len; ++i) {
                    selected.add(records[i].key)
                }

                this.updateSelection(selected)
            }
        },
        has: (key: string | number) => {
            return this._selection.enabled && (this._selection.items?.has(key) ?? false)
        },
        remove: (key: string | number) => {
            if (this._selection.enabled && this._selection.items.has(key)) {
                const selected = new Set<number | string>(this._selection.items)
                selected.delete(key)
                this.updateSelection(selected)
            }
        },
        removeAll: () => {
            if (this._selection.enabled && this._selection.items?.size > 0) {
                this.updateSelection(undefined)
            }
        },
        count: () => {
            let count = 0

            if (this._selection.enabled && this._selection.items?.size > 0) {
                const selected = this._selection.items
                const records = this.records
                const len = records.length

                for (let i = 0; i < len; ++i) {
                    if (selected.has(records[i].key)) ++count
                }
            }

            return count
        },
        values: () => {
            const result = []

            if (this._selection.enabled && this._selection.items?.size > 0) {
                const selected = this._selection.items
                const records = this.records
                const len = records.length

                for (let i = 0; i < len; ++i) {
                    if (selected.has(records[i].key)) result.push(records[i])
                }
            }

            return result
        }
    }

    private updateSelection(items: Set<number | string> | undefined, records?: DataSourceRecordSet) {
        const enabled = this._selection.enabled
        if (!enabled) return

        if (records === undefined) records = this.records

        const last = records.length - 1
        let state: SelectionGlobalState = "noRecords"

        if (items?.size > 0) {
            for (let i = 0; i <= last; ++i) {
                const selected = items.has(records[i].key)

                if (state === "noRecords") {
                    // Select initial state from first record.
                    state = selected ? (last === i ? "all" : "partial") : "none"
                } else if (state === "none" && selected) {
                    // First selected record and all previous were unselected. Can't be first record.
                    state = "partial"
                    break
                } else if (state === "partial" && !selected) {
                    // First unselected record and all previous were selected. Can't be first record.
                    break
                } else if (state === "partial" && last === i) {
                    // Last record. All previous were selected
                    state = "all"
                }
            }
        } else if (last >= 0) {
            state = "none"
        }

        const selection = { state, items, enabled }
        this._selection = selection
        this.setSelectionState(selection)
    }

    public set mode(val: "grid" | "list") {
        this._mode = val
        this.setModeState(val)
        this.setColumnState(PaginatedListContext.filterColumns(this._unprocessedColumns, val))
    }

    public get mode(): "grid" | "list" {
        return this._mode
    }

    public set currentPage(val: number) {
        this._currentPage = val
        this.setCurrentPageState(val)
    }

    public get currentPage(): number {
        return this._currentPage
    }

    public get recordsPerPage(): number {
        return this._recordsPerPage
    }

    public get maxPage(): number {
        return this._maxPage
    }

    public get columns(): Column[] {
        return this._columns
    }

    public get records(): DataSourceRecordSet {
        return this._records
    }

    public get getState(): PaginatedListState {
        return this.state
    }

    private setState(state: PaginatedListState) {
        this.state = state
    }

    private initialise() {
        if (this.state === PaginatedListState.Uninitialised) {
            this.setState(PaginatedListState.Loading)
            this.dataSource.load(this.filter).then(this.processDataSourceResult)
        }
    }

    public refresh() {
        if (this.state === PaginatedListState.Loaded) {
            this.setState(PaginatedListState.Loading)
            this.selection.removeAll()
            this.dataSource.load(this.filter).then(this.processDataSourceResult)
        } else if (this.state === PaginatedListState.Loading) {
            this.pendingRefresh = true
            this.dataSource.abortLoad()
        }
    }

    public download(filename: string) {
        const csvFormatter = this.csvFormatter
        const records = this.records
        const len = records.length
        const output = []

        for (let i = 0; i < len; ++i) {
            const record = csvFormatter ? csvFormatter(records[i]) : records[i].values
            const cols = record.length
            const csvRecord = []

            for (let j = 0; j < cols; ++j) {
                const value = record[j]
                const type = typeof value

                if (type === "number") csvRecord.push(value)
                else if (type !== "object" || value === null) csvRecord.push(`"${value}"`)
                else csvRecord.push('"' + value.toString() + '"')
            }

            output.push(csvRecord.join(","))
        }

        const blob = new Blob([output.join("\n")], { type: "text/csv" })
        saveAs(blob, filename)
    }

    private processDataSourceResult = (result: DataSourceResult) => {
        if (this.pendingRefresh) {
            this.pendingRefresh = false
            this.selection.removeAll()
            this.dataSource.load(this.filter).then(this.processDataSourceResult)
        } else if (this.pendingFilter) {
            this.pendingFilter = false
            this.dataSource.filter(this.filter).then(this.processDataSourceResult)
        } else if (result.aborted !== true) {
            this.setState(PaginatedListState.Loaded)
            if (result.columns) {
                this._unprocessedColumns = result.columns
                this.setColumnState(PaginatedListContext.filterColumns(result.columns, this.mode))
            }

            if (result.records) {
                this.setRecordState(result.records)
                this.updateSelection(this._selection.items, result.records)
            }

            if (result.error) this._appContext.processError(undefined, "Error", result.error)

            if (this._recordsPerPage > 0) {
                this.setMaxPageState(Math.ceil(result.totalRecords / this._recordsPerPage))
            }
        }
    }
}

interface TableHeaderProps {
    mode: "grid" | "list"
    selection: SelectionCallbacks
    columns: Column[]
}

function TableHeader({ selection, columns, mode }: TableHeaderProps) {
    const renderedColumns = []
    const columnLen = columns.length

    for (let i = 0; i < columnLen; ++i) {
        const column = columns[i]

        if ((column.mode ?? mode) !== mode) continue

        if (column.type === "select") {
            const state = selection.state()
            const props = { className: "row-selector", "aria-label": "", onClick: undefined, disabled: undefined }

            if (state === "all") {
                props.className = "row-selector selected"
                props["aria-label"] = "Unselect All"
                props.onClick = selection.removeAll
            } else if (state === "partial" || state === "none") {
                props.className = "row-selector"
                if (state === "partial") props.className += " intermediate"
                props["aria-label"] = "Select All"
                props.onClick = selection.addAll
            } else {
                props.className = "row-selector"
                props.disabled = true
            }

            renderedColumns.push(
                <th key="row-selector" className="row-selector">
                    <button type="button" {...props} />
                </th>
            )
        } else {
            renderedColumns.push(<th key={`c#${column.key}`}>{column.title}</th>)
        }
    }

    return (
        <thead>
            <tr>{renderedColumns}</tr>
        </thead>
    )
}

interface TableBodyProps {
    mode: "grid" | "list"
    selection: SelectionCallbacks
    columns: Column[]
    records: DataSourceRecordSet
    currentPage: number
    recordsPerPage: number
    gridButton: ActionButtonProps
}

function TableBody({ selection, columns, records, currentPage, recordsPerPage, mode, gridButton }: TableBodyProps) {
    const renderedRecords = []
    const columnLen = columns.length
    const recordStart = recordsPerPage > 0 ? Math.max(0, (currentPage - 1) * recordsPerPage) : 0
    const recordEnd = recordsPerPage > 0 ? Math.min(records.length, recordStart + recordsPerPage) : records.length

    for (let i = recordStart; i < recordEnd; ++i) {
        const record = records[i]
        const values = record.values
        const onRecordClick = record.onClick
        const renderedColumns = []

        for (let j = 0; j < columnLen; ++j) {
            const column = columns[j]
            // const value = values[j] ?? ""
            const value = values && values[j] !== undefined ? values[j] : ""

            if ((column.mode ?? mode) !== mode) continue

            if (column.type === "select") {
                const selected = selection.has(record.key)
                const buttonClass = `row-selector ${selected ? "selected" : ""}`
                const label = selected ? "Unselect Row" : "Select Row"
                const onClick = (e: MouseEvent): void => {
                    selection[selected ? "remove" : "add"](record.key)
                    e.stopPropagation()
                }

                renderedColumns.push(
                    <td key="row-selector" data-key={column.key} className="row-selector">
                        <button type="button" className={buttonClass} aria-label={label} onClick={onClick}></button>
                    </td>
                )
            } else {
                renderedColumns.push(
                    <td key={`c#${column.key}`} data-key={column.key}>
                        {value}
                    </td>
                )
            }
        }

        renderedRecords.push(
            <tr key={record.key} onClick={onRecordClick}>
                {renderedColumns}
            </tr>
        )
    }

    if (gridButton && mode === "grid" && recordEnd === records.length) {
        renderedRecords.push(
            <tr key="grid-button" className="grid-button">
                <td>
                    <ActionButton {...gridButton} />
                </td>
            </tr>
        )
    }

    return <tbody>{renderedRecords}</tbody>
}

interface PaginationProps {
    currentPage: number
    maxPage: number
    id?: string
    setCurrentPage: (page: number) => void
}

function Pagination({ currentPage, maxPage, setCurrentPage, id }: PaginationProps) {
    const pages = []
    const previousPage = currentPage > 1 ? currentPage - 1 : 1
    const nextPage = currentPage >= maxPage ? maxPage : currentPage + 1
    for (let page = 1, hasFirstSpacer = false, hasSecondSpacer = false; page <= maxPage; ++page) {
        if (!hasFirstSpacer && page > 2 && currentPage > 5) {
            pages.push(
                <li key="spacer-1" className="spacer">
                    ...
                </li>
            )
            page = currentPage - 3
            hasFirstSpacer = true
        } else if (!hasSecondSpacer && page > currentPage + 2 && page < maxPage - 1) {
            pages.push(
                <li key="spacer-2" className="spacer">
                    ...
                </li>
            )
            page = maxPage - 2
            hasSecondSpacer = true
        } else {
            const callbackPage = page
            pages.push(
                <li key={"page-" + page} className="page">
                    <button type="button" disabled={currentPage === page} onClick={() => setCurrentPage(callbackPage)}>
                        {page}
                    </button>
                </li>
            )
        }
    }

    const siteCurrentPage = localStorage.getItem("siteCurrentPage") ?? currentPage

    if (id === "site") {
        if (Number(siteCurrentPage) <= maxPage) {
            setCurrentPage(Number(siteCurrentPage))
        }
    }

    return (
        <ul className="pagination">
            <li key="previous" className="page page-previous">
                <button type="button" disabled={currentPage === 1} onClick={() => setCurrentPage(previousPage)}>
                    &lt;&lt; Previous
                </button>
            </li>
            {pages}
            <li key="next" className="page page-next">
                <button type="button" disabled={currentPage >= maxPage} onClick={() => setCurrentPage(nextPage)}>
                    Next &gt;&gt;
                </button>
            </li>
        </ul>
    )
}

interface PaginatedListProps<TFilterFieldValues extends FieldValues = FieldValues> {
    contextRef?: React.MutableRefObject<PaginatedListContext>
    title?: string
    id?: string
    mode?: "grid" | "list" | { initial: "grid" | "list"; switch: boolean }
    header?: () => ReactNode
    footer?: () => ReactNode
    columns?: Column[]
    dataSource: DataSource
    noRecordsMessage?: string
    csvConverter?: (record: DataSourceRecord) => (string | number)[]
    buttons?: ListActionButton[]
    gridButton?: ActionButtonProps
    filter?: TFilterFieldValues
    search?: boolean | SearchBoxProps
    recordsPerPage?: number
    setFilterTrigger?: () => ReactNode
}

export type ListActionButton = Omit<ActionButtonProps, "onClick"> & {
    onClick: (items: DataSourceRecordSet) => void
}

// interface PaginatedListContextWithDataServiceContext extends PaginatedListContext<FieldValues> {
//     dataServiceContext: DataServiceContext
// }

export default function PaginatedList<TFieldFormValues extends FieldValues = FieldValues>(
    props: PaginatedListProps<TFieldFormValues>
) {
    const context = PaginatedListContext.use(props)
    const getStatusFilter = () => ({
        filter_value: context?.filter?.filter_value ?? localStorage.getItem("filterValue") ?? "all"
    })

    useEffect(() => {
        if (typeof Storage !== "undefined") {
            window.addEventListener("beforeunload", function () {
                localStorage.removeItem("filterValue")
            })
        }
        const removeAllStatus =
            localStorage.getItem("filterValue") === "all" ? undefined : localStorage.getItem("filterValue")
        context.filter = {
            ...context.filter,
            filter_value: context?.filter?.filter_value || removeAllStatus
        }
    }, [])

    if (props.contextRef) props.contextRef.current = context

    if (context.getState === 1) return <FullScreenLoader />

    const columns = context.columns
    const columnCount = columns.length
    const searchProps = props.search ? (props.search === true ? {} : props.search) : undefined
    const headerBlocks = []
    const footerBlocks = []

    const columnTemplate = []
    for (let i = 0; i < columnCount; ++i) {
        const column = columns[i]
        columnTemplate[i] = column.template ?? (column.type === "select" ? "max-content" : "auto")
    }

    if (props.title) {
        headerBlocks.push(<h3 key="title">{props.title}</h3>)
    }

    if (props.header) {
        headerBlocks.push(<div key="custom-header" className="custom-headers" children={props.header()} />)
    }

    if (searchProps) {
        headerBlocks.push(
            <div key="search" className="search">
                <SearchBox
                    {...searchProps}
                    onSearch={(search) => {
                        context.search = search
                        searchProps.onSearch?.(search)
                    }}
                />
            </div>
        )
    }

    if (props.buttons?.length > 0) {
        headerBlocks.push(
            <div key="icons" className="actions global-actions icon-container">
                {props.buttons.map((button) => (
                    <ActionButton
                        {...button}
                        key={button.key}
                        onClick={() => button.onClick(context.selection.values())}
                    />
                ))}
            </div>
        )
    }

    if (typeof props.mode === "object" && props.mode.switch) {
        headerBlocks.push(
            <div key="mode" className="mode-change icon-container">
                <ActionButton
                    key="detail"
                    title="View as List"
                    icon="list-detail"
                    selected={context.mode === "list"}
                    onClick={() => {
                        context.mode = "list"
                        localStorage.setItem("viewMode", "list")
                    }}
                />
                <ActionButton
                    key="grid"
                    title="View as Grid"
                    icon="list-item"
                    selected={context.mode === "grid"}
                    onClick={() => {
                        context.mode = "grid"
                        localStorage.setItem("viewMode", "grid")
                    }}
                />
                <select
                    key="select"
                    className="select_right"
                    onChange={(e) => {
                        const selectedValue = e.target.value
                        const newFilter =
                            selectedValue === "all" ? { filter_value: undefined } : { filter_value: selectedValue }

                        context.filter = newFilter
                        context.currentPage = 1

                        localStorage.setItem("filterValue", selectedValue)
                        localStorage.setItem("siteCurrentPage", String(1))
                    }}
                    value={getStatusFilter().filter_value}
                >
                    <option value="all" selected>
                        Status...
                    </option>
                    <option value="ok">Ok</option>
                    <option value="warning">Warning</option>
                    <option value="error">Error</option>
                </select>
            </div>
        )
    }

    if (context.maxPage > 1) {
        footerBlocks.push(
            <Pagination
                key="pagination"
                currentPage={context.currentPage}
                maxPage={context.maxPage}
                id={props.id}
                setCurrentPage={(page) => {
                    if (props?.id === "site") {
                        localStorage.setItem("siteCurrentPage", String(page))
                    }
                    context.currentPage = page
                }}
            />
        )
    }

    if (props.footer) {
        footerBlocks.push(<div key="custom-footer" className="custom-footers" children={props.footer()} />)
    }

    const containerProperties = { "--paginated-list-cols": columnTemplate.length.toString() } as React.CSSProperties
    const listProperties = { gridTemplateColumns: columnTemplate.join(" ") } as React.CSSProperties

    return (
        <div className={"paginated-list paginated-list-" + context.mode} style={containerProperties}>
            {headerBlocks.length > 0 && <div key="header" className="header" children={headerBlocks} />}
            <table key="table" className={context.mode} style={listProperties}>
                <TableHeader selection={context.selection} columns={columns} mode={context.mode} />
                <TableBody
                    mode={context.mode}
                    selection={context.selection}
                    columns={columns}
                    records={context.records}
                    currentPage={context.currentPage}
                    recordsPerPage={context.recordsPerPage}
                    gridButton={props.gridButton}
                />
            </table>
            {context.records.length == 0 && (
                <div
                    key="no-records"
                    className="footer no-records"
                    children={props.noRecordsMessage ?? "No Records Found"}
                />
            )}
            {footerBlocks.length > 0 && <div key="footer" className="footer" children={footerBlocks} />}
        </div>
    )
}
