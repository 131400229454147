import React, { MouseEvent, SyntheticEvent } from "react"
import { AppContext, useAppContext } from "../../App"
import PaginatedList, {
    Column,
    DataSource,
    DataSourceRecord,
    ListActionButton,
    PaginatedListContext,
    RecordFetcher
} from "../../component/PaginatedList"
import ServiceProvider from "../../service/ServiceProvider"
import Site, { DefaultSiteImage } from "../../model/Site"
import SiteError from "../../model/SiteError"
import { DateTime, DurationLike } from "luxon"
import { useAuthenticatedNavigator, AuthenticatedNavigatorContext, Page } from "../../Authenticated"
import { DataServiceContext } from "../../service/data/DataService"
import { ActionButtonProps } from "../../component/ActionButton"
import { SiteShareDialog } from "../site/component/SiteShare"
import { SiteAddDialog } from "../site/component/SiteAdd"
import { ThingSiteData } from "../../model/Thing"

class SiteFetcher extends RecordFetcher<Site> {
    static COLUMNS: Column[] = [
        { key: "alert", title: null, template: "max-content" },
        { key: "select", type: "select", title: null },
        { key: "name", title: "Site Name" },
        { key: "address", title: "Site Address" },
        { key: "action", title: null, template: "max-content" },
        { key: "image", title: null, mode: "grid" }
    ]

    private readonly context: DashboardController
    private readonly appContext: AppContext
    private readonly navigator: AuthenticatedNavigatorContext

    constructor(context: DashboardController, appContext: AppContext, navigator: AuthenticatedNavigatorContext) {
        super()
        this.context = context
        this.appContext = appContext
        this.navigator = navigator
    }

    protected async execute(
        search: { q: string; filter_by?: string; filter_value: string },
        context: DataServiceContext
    ): Promise<Site[]> {
        try {
            this.navigator.triggerFilter(search.filter_value)
            return await ServiceProvider.dataService.searchSites(
                context,
                search.q,
                search.filter_by,
                // dataFilter
                search.filter_value
            )
        } catch (e: unknown) {
            this.appContext.processError(e, "Server Error", "Could not retrieve site listing")
            throw e
        }
    }

    protected process(record: Site): DataSourceRecord {
        const edit = (event: MouseEvent) => {
            event.stopPropagation()
            this.navigator.navigateTo(Page.SiteEditor, { siteId: record.id, siteName: record.name })
        }
        const share = (event: MouseEvent) => {
            event.stopPropagation()
            this.context.showShareSiteDialog([record])
        }
        const view = (event: MouseEvent) => {
            event.stopPropagation()
            this.navigator.navigateTo(Page.SiteDashboard, { siteId: record.id })
        }

        const onerror = (e: SyntheticEvent<HTMLImageElement>) => {
            const el = e.currentTarget

            if (el.src === record.imageUri && el.src !== DefaultSiteImage) {
                el.src = DefaultSiteImage
            }
        }

        return {
            key: record.id,
            source: record,
            onClick: view,
            values: [
                typeof record.status === "string" ? (
                    <div className={"site-status site-status-icon site-status-" + record.status}>
                        <span className="icon-img" data-icon={"site-" + record.status} />
                    </div>
                ) : null,
                null,
                record.name,
                record.address,
                <span className="icon-container">
                    <button type="button" className="link" title="View Site" onClick={view}>
                        <span className="icon-img" data-icon="view" />
                    </button>
                    <button type="button" className="link" title="Edit Site" onClick={edit}>
                        <span className="icon-img" data-icon="edit" />
                    </button>
                    <button type="button" className="link" title="Share Site" onClick={share}>
                        <span className="icon-img" data-icon="share" />
                    </button>
                </span>,
                <img src={record.imageUri ?? DefaultSiteImage} alt="" onError={onerror} />
            ]
        }
    }
}

class SiteErrorFetcher extends RecordFetcher<SiteError, { dateRange: string }> {
    private readonly appContext: AppContext

    static COLUMNS: Column[] = [
        { key: "timestamp", title: "Date/Time" },
        { key: "property", title: "Property" },
        { key: "error", title: "Error" },
        { key: "errorDetail", title: "Error Detail" }
    ]

    constructor(appContext: AppContext) {
        super()
        this.appContext = appContext
    }

    protected async execute(filter: { dateRange: string }, context: DataServiceContext): Promise<SiteError[]> {
        let diff: DurationLike

        if (filter?.dateRange) {
            const periodMap = { d: "days", m: "months" }
            const result = /^-([0-9]+)([dm])$/.exec(filter.dateRange)

            if (result) {
                const count = parseInt(result[1])
                const period = periodMap[result[2]]
                diff = { [period]: count }
            }
        }

        if (!diff) diff = { days: 7 }

        try {
            const dateFrom = DateTime.now().minus(diff).toISODate()
            return await ServiceProvider.dataService.searchSiteErrors(context, dateFrom)
        } catch (e: unknown) {
            this.appContext.processError(e, "Server Error", "Could not retrieve site errors")
            return []
        }
    }

    protected process(record: SiteError): DataSourceRecord {
        return {
            key: record.id,
            values: [
                record.timestamp.toFormat("dd/MM/yyyy HH:mm"),
                record.name,
                "Error Code: " + record.errorCode,
                record.errorDetail
            ]
        }
    }
}

export class DashboardController {
    private _navigator: AuthenticatedNavigatorContext
    private _siteDataSource: DataSource
    private _siteContextRef: React.MutableRefObject<PaginatedListContext>
    private _siteErrorDataSource: DataSource
    private _siteErrorContextRef: React.MutableRefObject<PaginatedListContext>
    private _appContext: AppContext

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    static use() {
        // Collect state references

        // Setup instance
        const ref = React.useRef<DashboardController>()
        const siteContextRef = React.useRef<PaginatedListContext>()
        const siteErrorContextRef = React.useRef<PaginatedListContext>()
        const appContext = useAppContext()
        const navigator = useAuthenticatedNavigator()

        // Check to see if we need to initialise a new context
        if (!ref.current) {
            const context = new DashboardController()
            context._appContext = appContext
            context._siteDataSource = new SiteFetcher(context, appContext, navigator).createDataSource(
                appContext.session
            )
            context._siteContextRef = siteContextRef
            context._siteErrorDataSource = new SiteErrorFetcher(appContext).createDataSource(appContext.session)
            context._siteErrorContextRef = siteErrorContextRef
            context._navigator = navigator
            ref.current = context
        }

        // Return instance
        return ref.current
    }

    public get navigator(): AuthenticatedNavigatorContext {
        return this._navigator
    }

    public get siteDataSource(): DataSource {
        return this._siteDataSource
    }

    public get siteContextRef(): React.MutableRefObject<PaginatedListContext> {
        return this._siteContextRef
    }

    public get siteErrorDataSource(): DataSource {
        return this._siteErrorDataSource
    }

    public get siteErrorContextRef(): React.MutableRefObject<PaginatedListContext> {
        return this._siteErrorContextRef
    }

    public showAddSiteDialog() {
        const modal = this._appContext.modal
        const dialog = new SiteAddDialog(modal, {
            onFound: (thing: ThingSiteData) => this.navigator.navigateTo(Page.ThingRegister, { thing })
        })
        modal.add(dialog)
    }

    public showShareSiteDialog(sites: Site[]) {
        const modal = this._appContext.modal
        const siteIds = sites.map((site) => site.id)
        modal.add(new SiteShareDialog(this._appContext, modal, siteIds))
    }

    public showNoSitesSelectedMessage() {
        this._appContext.modal.add({
            title: "Share Error",
            content: "Sorry, you must select at least one site to share."
        })
    }
}

export default function Dashboard() {
    const context = DashboardController.use()
    const siteErrorContextRef = context.siteErrorContextRef
    const siteContextRef = context.siteContextRef
    // const getSiteErrorFilter = () => ({ dateRange: siteErrorContextRef.current?.filter?.dateRange ?? "-7d" , filter_value: localStorage.getItem('filterValue')})
    const getSiteErrorFilter = () => ({ dateRange: siteErrorContextRef.current?.filter?.dateRange ?? "-7d"})
    const updateSiteErrorFilter = (e) => siteErrorContextRef.current?.setFilter({ dateRange: e.target.value })

    const addButton: ActionButtonProps = {
        key: "add",
        type: "primary",
        text: "Add New",
        icon: "add",
        onClick: () => context.showAddSiteDialog()
    }
    const shareButton: ListActionButton = {
        key: "share",
        type: "secondary",
        text: "Share",
        icon: "share",
        onClick: (items) => {
            if (items.length > 0) context.showShareSiteDialog(items.map((item) => item.source as Site))
            else context.showNoSitesSelectedMessage()
        }
    }

    return (
        <>
            <section className="columns">
                <section className="column list-sites">
                    <PaginatedList
                        contextRef={context.siteContextRef}
                        dataSource={context.siteDataSource}
                        mode={{ initial: "grid", switch: true }}
                        columns={SiteFetcher.COLUMNS}
                        buttons={[addButton, shareButton]}
                        gridButton={addButton}
                        title="Sites"
                        id="site"
                        search={true}
                        recordsPerPage={12}
                    />
                </section>
                <div className="vertical-rule" />
                <section className="column list-errors">
                    <PaginatedList
                        contextRef={context.siteErrorContextRef}
                        dataSource={context.siteErrorDataSource}
                        columns={SiteErrorFetcher.COLUMNS}
                        header={() => (
                            <select
                                key="select"
                                onChange={updateSiteErrorFilter}
                                value={getSiteErrorFilter().dateRange}
                            >
                                <option value="-7d">Last 7 day(s)</option>
                                <option value="-1m">Last 1 month(s)</option>
                                <option value="-3m">Last 3 month(s)</option>
                            </select>
                        )}
                        mode="grid"
                        title="Errors"
                        filter={getSiteErrorFilter()}
                        recordsPerPage={5}
                    />
                </section>
            </section>
        </>
    )
}
