// Source: https://github.com/ericblade/quagga2-react-example/blob/master/src/components/Scanner.js
import React, { useCallback, useLayoutEffect, useRef } from "react"
import Quagga, {
    QuaggaJSCodeReader,
    QuaggaJSConfigObject,
    QuaggaJSReaderConfig
} from "@ericblade/quagga2/dist/quagga.js"
import QrCodeReader from '@ericblade/quagga2-reader-qr';

Quagga.registerReader("qrcode", QrCodeReader)

function getMedian(arr) {
    arr.sort((a, b) => a - b)
    const half = Math.floor(arr.length / 2)
    if (arr.length % 2 === 1) {
        return arr[half]
    }
    return (arr[half - 1] + arr[half]) / 2
}

function getMedianOfCodeErrors(decodedCodes) {
    const errors = decodedCodes?.filter((x) => x.error !== undefined).map((x) => x.error)
    return getMedian(errors)
}

const defaultConstraints = {
    width: 640,
    height: 480
}

const defaultLocatorSettings = {
    patchSize: "medium",
    halfSample: true
}

const defaultDecoders: (QuaggaJSCodeReader | QuaggaJSReaderConfig)[] = ["qrcode", "ean_reader", "upc_reader"]

export type Props = {
    onScannerReady?: () => void
    onDetected: (code: string) => void
    onError?: (err: unknown) => void
    cameraId?: QuaggaJSConfigObject["inputStream"]["constraints"]["deviceId"]
    facingMode?: QuaggaJSConfigObject["inputStream"]["constraints"]["facingMode"]
    constraints?: QuaggaJSConfigObject["inputStream"]["constraints"]
    locator?: QuaggaJSConfigObject["locator"]
    numOfWorkers?: QuaggaJSConfigObject["numOfWorkers"]
    decoders?: QuaggaJSConfigObject["decoder"]["readers"]
    locate?: QuaggaJSConfigObject["locate"]
}

const Scanner = ({
    onDetected,
    onError,
    onScannerReady,
    cameraId,
    facingMode,
    constraints = defaultConstraints,
    locator = defaultLocatorSettings,
    numOfWorkers = navigator.hardwareConcurrency || 0,
    decoders = defaultDecoders,
    locate = true
}: Props) => {
    const scannerRef = useRef<HTMLDivElement>()

    const errorCheck = useCallback(
        (result) => {
            if (!onDetected) {
                return
            }
            if (result?.codeResult?.format === "qr_code") {
                onDetected(result.codeResult.code)
            } else {
                const err = getMedianOfCodeErrors(result.codeResult.decodedCodes)
                // if Quagga is at least 75% certain that it read correctly, then accept the code.
                if (err < 0.25) {
                    onDetected(result.codeResult.code)
                }
            }
        },
        [onDetected]
    )

    const handleProcessed = (result) => {
        const drawingCtx = Quagga.canvas.ctx.overlay
        const drawingCanvas = Quagga.canvas.dom.overlay
        drawingCtx.font = "24px Arial"
        drawingCtx.fillStyle = "green"

        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(
                    0,
                    0,
                    parseInt(drawingCanvas.getAttribute("width")),
                    parseInt(drawingCanvas.getAttribute("height"))
                )
                result.boxes
                    .filter((box) => box !== result.box)
                    .forEach((box) => {
                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "purple", lineWidth: 2 })
                    })
            }

            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "blue", lineWidth: 2 })
            }

            if (result?.location) {
                drawingCtx.clearRect(
                    0,
                    0,
                    parseInt(drawingCanvas.getAttribute("width")),
                    parseInt(drawingCanvas.getAttribute("height"))
                )
                const { topLeftCorner, topRightCorner, bottomLeftCorner, bottomRightCorner } = result.location

                drawingCtx.strokeStyle = "blue"
                drawingCtx.lineWidth = 2
                drawingCtx.beginPath()
                drawingCtx.moveTo(topLeftCorner.x, topLeftCorner.y)
                drawingCtx.lineTo(topRightCorner.x, topRightCorner.y)
                drawingCtx.lineTo(bottomRightCorner.x, bottomRightCorner.y)
                drawingCtx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y)
                drawingCtx.closePath()
                drawingCtx.stroke()
            }
        }
    }

    useLayoutEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        Quagga.init(
            {
                inputStream: {
                    type: "LiveStream",
                    constraints: {
                        ...constraints,
                        ...(cameraId && { deviceId: cameraId }),
                        ...(!cameraId && { facingMode })
                    },
                    target: scannerRef.current
                },
                locator,
                numOfWorkers,
                decoder: { readers: decoders },
                locate
            },
            (err) => {
                Quagga.onProcessed(handleProcessed)

                if (err) {
                    onError?.(err)
                    return
                }
                if (scannerRef && scannerRef.current) {
                    Quagga.start()
                    onScannerReady?.()
                }
            }
        )

        Quagga.onDetected(errorCheck)

        return () => {
            Quagga.offDetected(errorCheck)
            Quagga.offProcessed(handleProcessed)
            // noinspection JSIgnoredPromiseFromCall
            Quagga.stop()
        }
    }, [cameraId, onDetected, onScannerReady, scannerRef, errorCheck, constraints, locator, decoders, locate])

    return (
        <div key="scanner" ref={scannerRef} className="barcode-scanner">
            <canvas className="drawingBuffer" />
        </div>
    )
}

export default Scanner
