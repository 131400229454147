import React, { useState, useRef, Dispatch, ReactNode } from "react"
import Session from "./model/Session"
import Authenticated from "./Authenticated"
import Unauthenticated from "./Unauthenticated"
import Modal, { ModalContext } from "./component/Modal"
import { SessionError } from "./service/data/DataService"
import { DateTime } from "luxon"
import { SiteInformationItems, siteInformationObj } from "./util/constant"
import FullScreenLoader from "./component/Loading"

export interface AppContext {
    set session(session: Session | null)
    get session(): Session | null
    set setLoading(value: true | false)
    get loading(): true | false
    get modal(): ModalContext
    get logger(): Console
    get siteInformation(): SiteInformationItems

    processError(error: unknown, title: string, content: ReactNode): void
}

const STORAGE_SESSION_KEY = "$session$"

const readSessionFromStorage = (): Session | undefined => {
    const raw = window.localStorage.getItem(STORAGE_SESSION_KEY)

    if (raw) {
        const session: Session = JSON.parse(raw, (key, value) => {
            if (key === "expiration" || key === "login") return DateTime.fromISO(value)
            else return value
        })

        if (session?.expiration && session.expiration > DateTime.now()) return session
        else window.localStorage.removeItem(STORAGE_SESSION_KEY)
    }

    return undefined
}

const writeSessionToStorage = (session: Session | null | undefined): void => {
    if (session) window.localStorage.setItem(STORAGE_SESSION_KEY, JSON.stringify(session))
    else window.localStorage.removeItem(STORAGE_SESSION_KEY)
}

class AppContextImpl implements AppContext {
    private _session: Session | null
    private _setStateSession: Dispatch<React.SetStateAction<Session>>
    private _loading: true | false
    private _setLoading: React.Dispatch<React.SetStateAction<true | false>>
    private _modalContext: React.MutableRefObject<ModalContext>
    private _theme: string

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    static use(props: AppProps): AppContextImpl {
        // Collect state references
        const [session, setSession] = useState<Session>(readSessionFromStorage)
        const [loading, setLoading] = React.useState<true | false>(false)
        const modalContext = useRef<ModalContext>(null)

        // Setup instance
        const ref = React.useRef<AppContext>()

        // Check to see if we need to initialise a new context
        if (!ref.current) {
            const context = new AppContextImpl()
            context._session = session
            context._setStateSession = setSession
            context._loading = loading
            context._setLoading = setLoading
            context._modalContext = modalContext
            context._theme = props.theme
            ref.current = context
        }

        // Return instance
        return ref.current as AppContextImpl
    }

    // noinspection JSUnusedGlobalSymbols
    set session(session: Session | null) {
        this._setStateSession(session)
        this._session = session

        writeSessionToStorage(session)
    }

    get session() {
        return this._session
    }

    set setLoading(value: true | false) {
        this._loading = value
        this._setLoading(value)
    }

    get loading(): true | false {
        return this._loading
    }

    get logger() {
        return console
    }

    get modal(): ModalContext {
        return this._modalContext.current
    }

    get siteInformation(): SiteInformationItems {
        return siteInformationObj[this._theme] || null
    }

    processError(error: unknown, title: string, content: ReactNode) {
        if (error instanceof SessionError) {
            this.session = null

            this.modal.add({
                title: "Session Timed Out",
                content: (
                    <>
                        <p>Sorry, your session has expired.</p>
                        <p>You must re-authenticate to continue using the portal.</p>
                    </>
                )
            })
        } else {
            this.logger.error(error)
            this.modal.add({ title, content })
        }
    }

    getModalContextRef(): React.MutableRefObject<ModalContext> {
        return this._modalContext
    }
}

const ReactAppContext = React.createContext<AppContext>(undefined)

export function useAppContext() {
    return React.useContext(ReactAppContext)
}

export type AppProps = { theme: "dux" | "thermann" }

export default function App(props: AppProps) {
    const controller = AppContextImpl.use(props)
    const [renderAgain, setRenderAgain] = useState(false)
    const renderFunction = () => {
        setRenderAgain(true)
    }
    return (
        <ReactAppContext.Provider value={controller}>
            {controller.loading && <FullScreenLoader />}
            {controller.session ? <Authenticated setRenderAgain={renderFunction} /> : <Unauthenticated />}
            <Modal context={controller.getModalContextRef()} />
        </ReactAppContext.Provider>
    )
}
