import Session from "../../model/Session"
import { UserData } from "../../model/User"

export interface IdentityData {
    email: string
    password?: string
    name: string
    phone: string
    company: string
    state: string
    country: string
}

export interface Identity extends IdentityData {
    id: string
}

export enum Error {
    None,
    InvalidParameters,
    EmailNotFound,
    InvalidPassword,
    EmailAlreadyExists,
    Custom
}

const ErrorMessages = {
    [Error.InvalidParameters]: "Input parameters failed validation",
    [Error.EmailNotFound]: "Email address has not been registered",
    [Error.InvalidPassword]: "Email address and/or password is not valid",
    [Error.EmailAlreadyExists]: "Email address has already been registered"
}

export interface Result {
    errorCode?: Error
    errorDescription?: string
}

export interface AuthenticationResult extends Result {
    authenticated: boolean
    session?: Session
}

export interface IdentityResult extends Result {
    identity?: Identity
}

export interface RegistrationResult extends Result {
    registered: boolean
    id?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ResetPasswordResult extends Result {}

export default abstract class IdentityService {
    async authenticate(email: string, password: string): Promise<AuthenticationResult> {
        const result = await this.doAuthenticate(email, password)
        return IdentityService.processResult(result)
    }

    async logout(session: Session): Promise<Result> {
        const result = await this.doLogout(session)
        return IdentityService.processResult(result)
    }

    async register(
        email: string,
        password: string,
        name: string,
        phone: string,
        company: string,
        state: string,
        country: string
    ): Promise<RegistrationResult> {
        const result = await this.doRegister({
            email,
            password,
            name,
            phone,
            company,
            state,
            country
        })

        return IdentityService.processResult(result)
    }

    async resetPassword(email: string): Promise<ResetPasswordResult> {
        const result = await this.doResetPassword(email)
        return IdentityService.processResult(result)
    }

    async verify(email: string, code: string): Promise<Result> {
        const result = await this.doVerify(email, code)
        return IdentityService.processResult(result)
    }

    abstract fetch(session: Session): Promise<IdentityResult>

    abstract update(
        session: Session,
        userId: string,
        user: UserData,
        password?: { new: string; current: string }
    ): Promise<Result>

    private static processResult<TResult extends Result>(result: TResult): TResult {
        if (result.errorCode !== Error.None && Object.prototype.hasOwnProperty.call(ErrorMessages, result.errorCode)) {
            result.errorDescription = ErrorMessages[result.errorCode]
        }

        return result
    }

    protected abstract doAuthenticate(email: string, password: string): Promise<AuthenticationResult>
    protected abstract doLogout(session: Session)
    protected abstract doRegister(identity: IdentityData): Promise<RegistrationResult>
    protected abstract doResetPassword(email: string): Promise<ResetPasswordResult>
    protected abstract doVerify(email: string, code: string): Promise<Result>
}
