import React, { Dispatch, ReactNode, useCallback, useState } from "react"
import { AppContext, useAppContext } from "./App"
import Dashboard from "./screen/home/Dashboard"
import ThingRegister, { Props as ThingRegisterProps } from "./screen/thing/Register"
import SiteEditor, { EditorProps as SiteEditorProps } from "./screen/site/Editor"
import SiteDashboard, { SiteDashboardProps } from "./screen/site/SiteDashboard"
import { OverloadInstance, Overload } from "./util/OverloadUtil"
import { ActionButton } from "./component/ActionButton"
import Account from "./screen/home/Account"
import ServiceProvider from "./service/ServiceProvider"
import Site from "./model/Site"
import { DataServiceContext } from "./service/data/DataService"
import { SiteInformationItems } from "./util/constant"

export const enum Page {
    Account = "account",
    Minimize = "minimize",
    Maximize = "maximize",
    Dashboard = "dashboard",
    ThingRegister = "thing-register",
    SiteEditor = "site-editor",
    SiteDashboard = "site-overview",
    Logout = "logout"
}

type PagePropMappings = {
    [Page.SiteEditor]: SiteEditorProps
    [Page.SiteDashboard]: SiteDashboardProps
    [Page.ThingRegister]: ThingRegisterProps
}

type NavigateTo = Overload<PagePropMappings, Page>
type ActivePage<TPage extends Page = Page> = OverloadInstance<PagePropMappings, TPage>

export type AuthenticatedNavigatorContext = {
    readonly navigateTo: NavigateTo
    addSidebarMinimisedListener(listener: (minimised: boolean) => void)
    removeSidebarMinimisedListener(listener: (minimised: boolean) => void)
    back(): void
    canGoBack(): boolean
    triggerFilter(latestValue: string): void
}

export type Icon =
    | "user"
    | "home"
    | "minimize"
    | "logout"
    | "site"
    | "menu-expand"
    | "menu-shrink"
    | "location"
    | undefined

export interface MenuItem {
    key: string
    icon: Icon
    title: string
    icon2: Icon
    callback: () => void
}

class AuthenticatedController {
    public _triggerOnSidebarMinimised = false
    private _appContext: AppContext
    private _activePage: ActivePage
    private _userInitials: string
    private _sidebarMinimized: boolean
    private _onSidebarMinimized: ((minimized: boolean) => void)[] = []
    private _sites: Site[]
    private _setFilterValue: (value: string) => void
    private setPage: Dispatch<React.SetStateAction<ActivePage>>
    private setSidebarMinimized: Dispatch<React.SetStateAction<boolean>>
    private setSites: Dispatch<React.SetStateAction<Site[]>>

    public readonly Navigation: Partial<Record<Page, () => void>> = {
        [Page.Account]: () => this.context.navigateTo(Page.Account),
        [Page.Dashboard]: () => {
            localStorage.removeItem("siteCurrentPage")
            localStorage.removeItem("filterValue")
            this.context.navigateTo(Page.Dashboard)
        },
        [Page.Minimize]: () => (this.sidebarMinimized = true),
        [Page.Maximize]: () => {
            this.sidebarMinimized = false
        },
        [Page.Logout]: () => {
            const session = this._appContext.session
            this._appContext.session = null
            localStorage.removeItem("siteCurrentPage")
            localStorage.removeItem("filterValue")
            if (session) {
                // noinspection JSIgnoredPromiseFromCall
                ServiceProvider.identityService.logout(session)
            }
        }
    } as const

    private static readonly PageTitles = {
        [Page.Account]: "Edit Your Profile",
        [Page.Minimize]: "Minimise",
        [Page.Maximize]: "Maximise",
        [Page.Dashboard]: "Global Dashboard",
        [Page.ThingRegister]: "Add A Site",
        [Page.SiteEditor]: "Edit Site",
        [Page.SiteDashboard]: "Site Dashboard",
        [Page.Logout]: "Logout"
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    public _menuItems: MenuItem[] = []

    get menuItems(): MenuItem[] {
        return this.generateMenuItems("all")
    }

    updateMenuItems(value: string): void {
        const a = this.generateMenuItems(value)
        this._menuItems = a
    }

    get page(): Page {
        return this._activePage.key
    }

    get pageData(): any {
        const page = this._activePage
        return page.data
    }

    get pageTitle(): ReactNode {
        const page = this._activePage
        const titleText = AuthenticatedController.PageTitles[page.key] ?? ""
        const siteName = page.data && "siteName" in page.data ? ": " + page.data.siteName : null

        const title = <span key="title" className="title" children={titleText} />
        const subTitle = siteName !== null ? <span key="subtitle" className="subtitle" children={siteName} /> : null
        return [title, subTitle]
    }

    private readonly navigationStack: ActivePage[] = []

    public generateMenuItems(latestValue: string): MenuItem[] {
        const items: MenuItem[] = [
            this._sidebarMinimized
                ? {
                      key: Page.Minimize,
                      icon: "menu-expand",
                      title: AuthenticatedController.PageTitles[Page.Maximize],
                      icon2: undefined,
                      callback: this.Navigation[Page.Maximize]
                  }
                : {
                      key: Page.Maximize,
                      icon: "menu-shrink",
                      title: AuthenticatedController.PageTitles[Page.Minimize],
                      icon2: undefined,
                      callback: this.Navigation[Page.Minimize]
                  },
            {
                key: Page.Dashboard,
                icon: "home",
                title: AuthenticatedController.PageTitles[Page.Dashboard],
                icon2: undefined,
                callback: this.Navigation[Page.Dashboard]
            },
            {
                key: Page.Logout,
                icon: "logout",
                title: AuthenticatedController.PageTitles[Page.Logout],
                icon2: undefined,
                callback: this.Navigation[Page.Logout]
            }
        ]
        const getFilterValue = () => latestValue || localStorage.getItem("filterValue") || "all";
        const filterValue = getFilterValue();
        const isAllFilter = filterValue === "all";
        const filteredData = this._sites && this._sites.filter((item) => (isAllFilter ? true : item.status === filterValue))
        if (this._sites?.length > 0) {
            const siteItems: MenuItem[] = filteredData.map((site) => {
                const icon2 = `site-${site.status}`
                return {
                    key: "site#" + site.id,
                    icon: "location",
                    // icon: undefined,
                    title: site.name,
                    icon2: icon2 || "user",
                    callback: () => this.context.navigateTo(Page.SiteDashboard, { siteId: site.id })
                } as MenuItem
            })

            items.splice(2, 0, ...siteItems)
        }
        return items
    }

    readonly context: AuthenticatedNavigatorContext = {
        canGoBack: () => {
            return this.navigationStack.length > 0
        },
        back: () => {
            const page = this.navigationStack.pop()
            if (page !== undefined) this.setPage(page)
        },
        triggerFilter: (latestValue) => {
            this.updateMenuItems(latestValue || "all")
            this._setFilterValue(latestValue)
        },
        addSidebarMinimisedListener: (listener: (minimised: boolean) => void) => {
            this._onSidebarMinimized.push(listener)
        },
        removeSidebarMinimisedListener: (listener: (minimised: boolean) => void) => {
            this._onSidebarMinimized = this._onSidebarMinimized.filter((item) => item === listener)
        },
        navigateTo: <TPage extends Page>(
            key: TPage,
            data?: TPage extends keyof PagePropMappings ? PagePropMappings[TPage] : never
        ) => {
            const previousPage = this._activePage
            this.setPage({ key, data } as ActivePage<TPage>)

            if (key === Page.Dashboard) {
                this.navigationStack.splice(0, this.navigationStack.length)
            } else {
                this.navigationStack.push(previousPage)
            }
        }
    }

    get userName(): string {
        const user = this._appContext.session.identity
        return user.name ?? user.email ?? "user#" + user.id
    }

    get userInitials(): string {
        return this._userInitials
    }

    get userRole(): string {
        return "Admin"
    }

    get siteInformation(): SiteInformationItems {
        return this._appContext.siteInformation
    }

    get sidebarMinimized(): boolean {
        return this._sidebarMinimized
    }

    set sidebarMinimized(value: boolean) {
        const changed = value !== this._sidebarMinimized
        this._sidebarMinimized = value
        this.setSidebarMinimized((prev) => !prev)
        const a = this.generateMenuItems(localStorage.getItem("filterValue"))
        this._menuItems = a
        if (changed) this._triggerOnSidebarMinimised = value
    }

    render() {
        const activePage = this._activePage

        switch (activePage.key) {
            case Page.Account:
                return <Account />
            case Page.Dashboard:
                return <Dashboard />
            case Page.ThingRegister:
                return <ThingRegister {...activePage.data} />
            case Page.SiteEditor:
                return <SiteEditor {...activePage.data} />
            case Page.SiteDashboard:
                return <SiteDashboard {...activePage.data} />
            default:
                return undefined
        }
    }

    private static getInitials(fullName: string): string {
        const names = fullName.trim().split(" ")
        let initials = ""
        if (names[0]) initials += names[0].charAt(0).toUpperCase()
        if (names[1]) initials += names[names.length - 1].charAt(0).toUpperCase()
        return initials
    }

    public static use(setFilterValue: (value: string) => void) {
        const ref = React.useRef<AuthenticatedController>()
        const appContext = useAppContext()
        const [page, setPage] = useState<ActivePage>({ key: Page.Dashboard })
        const [sidebarMinimized, setSidebarMinimized] = useState<boolean>(true)
        const [sites, setSites] = useState<Site[] | undefined>(undefined)

        if (!ref.current) {
            ref.current = new AuthenticatedController()

            const context = new DataServiceContext(appContext.session)
            ServiceProvider.dataService.getSites(context).then(setSites)
        }

        const controller = ref.current
        controller._appContext = appContext
        controller._activePage = page
        controller._sidebarMinimized = sidebarMinimized
        controller._userInitials = AuthenticatedController.getInitials(controller.userName)
        controller._sites = sites
        controller._setFilterValue = setFilterValue
        controller.setPage = setPage
        controller.setSidebarMinimized = setSidebarMinimized
        controller.setSites = setSites

        React.useEffect(() => {
            // Set the filter value in the provided callback function
            setFilterValue && setFilterValue(localStorage.getItem("filterValue") || "all")
        }, [controller, setFilterValue])

        React.useEffect(() => {
            if (controller._triggerOnSidebarMinimised) {
                controller._onSidebarMinimized.forEach((callback) => callback(sidebarMinimized))
                controller._triggerOnSidebarMinimised = false
            }
        })
        return controller
    }
}

const AuthenticatedNavigator = React.createContext<AuthenticatedNavigatorContext>(undefined)

export function useAuthenticatedNavigator() {
    return React.useContext(AuthenticatedNavigator)
}

function UserAvatar({ controller }: { controller: AuthenticatedController }) {
    const initials = controller.userInitials
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className="user-avatar">
            <circle cx="32" width="64" height="64" cy="32" r="32" />
            <text x="50%" y="50%" children={initials} />
        </svg>
    )
}

export default function Authenticated({ setRenderAgain }) {
    const [filterValue, setFilterValue] = useState("all")

    const controller = AuthenticatedController.use(setFilterValue)

    React.useEffect(() => {
        setRenderAgain && setRenderAgain(Math.random()) // or any other value that triggers a re-render
    }, [filterValue, setRenderAgain])

    const navigateToGlobalDashboard = useCallback(() => {
        localStorage.removeItem("siteCurrentPage")
        controller.context.navigateTo(Page.Dashboard)
    }, [controller])

    const menuItems = controller._menuItems.map((menuItem: MenuItem) => {
        let isSelected = null
        if (controller.page === "site-overview") {
            if (menuItem?.key?.indexOf("#")) {
                if (controller?.pageData) {
                    isSelected = controller?.pageData?.siteId === menuItem?.key?.split("#")[1]
                }
            }
        } else {
            isSelected = menuItem.key === controller.page
        }
        const changeLayoutSidebar = !controller.sidebarMinimized && menuItem.key.toString().includes("site#")
        const marginLeftStyle = changeLayoutSidebar ? "15px" : ""

        return (
            // <li key={menuItem.key} data-key={menuItem.key} className={isSelected ? "selected" : null}>
            <li
                key={menuItem.key}
                data-key={menuItem.key}
                className={isSelected ? "selected" : null}
                style={{ marginLeft: marginLeftStyle }}
            >
                <ActionButton
                    key="btn"
                    icon={menuItem.icon}
                    text={menuItem.title}
                    icon2={menuItem.icon2}
                    onClick={menuItem.callback}
                    disabled={isSelected}
                />
            </li>
        )
    })

    const seprateOperations = menuItems.filter((item) => !item.key.toString().includes("site#"))
    return (
        <div className={"theme theme-app authenticated page page-" + controller.page}>
            <AuthenticatedNavigator.Provider value={controller.context}>
                <nav className={"dark " + (controller.sidebarMinimized ? " minimized" : "")}>
                    <header>
                        <button type="button" className="link" onClick={navigateToGlobalDashboard}>
                            <div className="logo-img"></div>
                        </button>
                        {controller.siteInformation ? controller.siteInformation?.logoText : <></>}
                    </header>
                    <ul>{controller.sidebarMinimized ? seprateOperations : menuItems}</ul>
                    {/* <ul>{menuItems}</ul> */}
                </nav>
                <section className="body">
                    <header>
                        <h2>{controller.pageTitle}</h2>
                        <div>
                            <button
                                type="button"
                                className="profile link"
                                onClick={controller.Navigation[Page.Account]}
                            >
                                <div className="user">
                                    <div className="user-name">{controller.userName}</div>
                                    <div className="user-role">{controller.userRole}</div>
                                </div>
                                <UserAvatar controller={controller} />
                            </button>
                            <div className="logout">
                                <ActionButton
                                    key={Page.Logout}
                                    icon="logout"
                                    title="Logout"
                                    onClick={controller.Navigation[Page.Logout]}
                                />
                            </div>
                        </div>
                    </header>
                    <section className="content" children={controller.render()} />
                </section>
            </AuthenticatedNavigator.Provider>
        </div>
    )
}
