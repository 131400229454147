import React, { SetStateAction, useCallback, useContext } from "react"
import { AppContext, useAppContext } from "../../../App"
import {
    createFormContext,
    FieldOptions,
    Form,
    FormController,
    FormField,
    renderFormInputs
} from "../../../component/Form"
import { BaseDialog, DialogButton, ModalContext } from "../../../component/Modal"
import { ActionButton } from "../../../component/ActionButton"
import Scanner from "../../../component/Scanner"
import ServiceProvider from "../../../service/ServiceProvider"
import { DataServiceContext } from "../../../service/data/DataService"
import { ThingSiteData } from "../../../model/Thing"
import FullScreenLoader from "../../../component/Loading"

const countryArr = [
    {
        code: "AU",
        name: "Australia"
    },
    {
        code: "NZ",
        name: "New Zealand"
    }
]

type FormValues = {
    input: string
    submit: string
}

const FormContext = createFormContext<FormValues>()

const inputField: FormField<FormValues> = {
    name: "input",
    title: "Enter the system's serial number or MAC address below",
    label: (
        <span>
            <span>Enter the system's serial number or MAC address below</span>
            <span className="info-icon tooltip-info">&#9432;
            <span className="tooltiptext">Enter the system's serial number or MAC address below</span>
            </span>
        </span>
    ),
    input: { element: "input", props: { placeholder: "Serial number or MAC Address", disabled: false } },
    options: FieldOptions.Required
}

const inputs: FormField<FormValues>[] = [inputField]

export type SiteAddDialogProps = {
    onFound: (props: ThingSiteData) => void
    onError?: (message: unknown) => void
    onCancel?: () => void
}

export type SiteAddProps = SiteAddDialogProps & {
    onLoading?: (loading: boolean) => void
    onSubmit?: () => void
    onValidationError?: () => void
    controller: React.MutableRefObject<SiteAddController>
}

export class SiteAddController {
    public static readonly FORM_NAME = "site-add"
    public readonly appContext: AppContext
    public readonly formControllerRef: React.RefObject<FormController<FormValues>> = { current: undefined }
    private props: SiteAddProps
    private _scanning = false
    private _setScanning: React.Dispatch<SetStateAction<boolean>>
    private _loading = false
    private _setLoading: React.Dispatch<SetStateAction<boolean>>

    constructor(appContext: AppContext) {
        this.appContext = appContext
    }

    public get scanning() {
        return this._scanning
    }

    public set scanning(scanning: boolean) {
        if (scanning === this._scanning) return
        this._scanning = scanning
        this._setScanning(scanning)
    }

    public get loading() {
        return this._loading
    }

    private set loading(loading: boolean) {
        if (loading === this._loading) return
        this._loading = loading
        this._setLoading(loading)
        this.props.onLoading?.(loading)
    }

    public startScanner() {
        this.scanning = true
    }

    public stopScanner() {
        this.scanning = false
    }

    public onScan(code: string): boolean {
        const form = this.formControllerRef.current
        if (!form || !(code?.length > 0)) return false
        form.setValue("input", code)
        return true
    }

    public onScanError(err: unknown): void {
        this.scanning = false
        this.appContext.logger.error("Barcode Scanner Error", err)
        alert("Sorry, we could not start the Barcode Scanner")
    }

    public async triggerSubmit(): Promise<boolean> {
        const formController = this.formControllerRef.current
        if (!formController) return
        await formController.triggerSubmit()
    }

    private readonly getCountryCode = (countryName: string) => {
        if (countryName) {
            const country = countryArr.find((item) => item.name === countryName)
            return country ? country?.code : ""
        } else {
            return ""
        }
    }

    private readonly validateAndFormatInput = (value: string) => {
        const isNumeric = /^\d+$/.test(value)
        const doesNotStartWithThreeZeros = !/^000/.test(value)
        const doesNotContainDotOrHyphen = !/[.-]/.test(value)

        if (isNumeric && doesNotStartWithThreeZeros && doesNotContainDotOrHyphen && value.length === 10) {
            const formatted = `${(parseFloat(value.substring(0, 4)) / 100).toFixed(2)}`
            const [integerPart, decimalPart] = formatted.split(".")
            const remainingPart = value.substring(4)
            return `${integerPart}.${decimalPart} - ${remainingPart}`
        } else {
            return value
        }
    }

    public readonly onSubmit = () => {
        const context = new DataServiceContext(this.appContext.session)
        const formController = this.formControllerRef.current
        const input = this.validateAndFormatInput(formController?.getValue("input"))

        this.scanning = false
        this.loading = true

        ServiceProvider.dataService.lookupSerialNumber(context, input).then(
            (data) => {
                this.props.onFound({
                    id: data.id,
                    serialNumber: data.serialNumber,
                    macAddress: data.macAddress,
                    type: data.type,
                    name: data?.name ?? "",
                    address: data?.address ?? "",
                    city: data?.city ?? "",
                    postCode: data?.postCode ?? "",
                    state: data?.state ?? "",
                    country: this.getCountryCode(data?.country)
                })
                this.props.onSubmit?.()
                this.loading = false
            },
            (error) => {
                this.loading = false
                this.appContext.logger.error("Error looking up serial number or MAC address", error)
                this.appContext.processError(
                    error,
                    "Lookup Error",
                    "Sorry, we could not find the serial number or MAC address"
                )
            }
        )
    }

    public readonly onValidationError = () => this.props.onValidationError?.()

    public static use(props: SiteAddProps): SiteAddController {
        const appContext = useAppContext()
        const controllerRef = React.useRef<SiteAddController>()
        const [scanning, setScanning] = React.useState<boolean>()
        const [loading, setLoading] = React.useState<boolean>()

        if (controllerRef.current === undefined) {
            controllerRef.current = new SiteAddController(appContext)
        }

        const controller = controllerRef.current
        controller._setScanning = setScanning
        controller._setLoading = setLoading
        controller._scanning = scanning
        controller._loading = loading
        controller.props = props
        if (props.controller) props.controller.current = controller
        return controller
    }
}

export class SiteAddDialog extends BaseDialog {
    private modal: ModalContext
    private readonly submitButton: DialogButton
    private readonly onLoading: (loading: boolean) => void
    private readonly onSubmit: () => void
    private readonly onError: () => void
    private ref: React.MutableRefObject<SiteAddController> = { current: undefined }

    constructor(modal: ModalContext, props: SiteAddDialogProps) {
        super()

        this.modal = modal
        this.title = "Add A Site"
        this.close = { enabled: true, content: "Cancel", className: "secondary" }
        this.submitButton = {
            key: "submit",
            className: "primary",
            content: "Submit",
            callback: () => {
                const controller = this.ref.current
                if (!controller) modal.close(this)
                else if (!controller.loading) {
                    // noinspection JSIgnoredPromiseFromCall
                    controller.triggerSubmit()
                }
            }
        }

        this.buttons = [this.submitButton]

        this.onLoading = (loading: boolean) => {
            this.submitButton.disabled = loading
            modal.refresh(this)
        }

        this.onSubmit = () => modal.close(this)
        this.onHide = () => this.ref.current?.stopScanner()

        this.content = () => (
            <SiteAdd {...props} controller={this.ref} onLoading={this.onLoading} onSubmit={this.onSubmit} />
        )
    }
}

export function SiteAdd(props: SiteAddProps) {
    const controller = SiteAddController.use(props)

    return (
        <div id={SiteAddController.FORM_NAME + "-form-container"} className="form-container content-site-add">
            <Form
                context={FormContext}
                name={SiteAddController.FORM_NAME}
                controller={controller.formControllerRef}
                onSubmit={controller.onSubmit}
                onError={controller.onValidationError}
            >
                <SiteAddFormContent
                    controller={controller}
                    scanning={controller.scanning}
                    loading={controller.loading}
                />
            </Form>
        </div>
    )
}

function SiteAddFormContent({ controller, scanning, loading }: { controller; scanning: boolean; loading: boolean }) {
    inputField.input.props.disabled = loading

    const formContext = useContext(FormContext)
    const renderedInputs = renderFormInputs(formContext, inputs)
    const showScanner = useCallback(() => controller.startScanner(), [controller])
    const onScanDetected = useCallback((result) => controller.onScan(result), [controller])
    const onScanError = useCallback((err) => controller.onScanError(err), [controller])

    return (
        <div className="screen screen-site-add-scan">
            {loading && <FullScreenLoader />}
            {scanning && <Scanner key="scanner" onDetected={onScanDetected} onError={onScanError} />}
            <div key="input" className="screen-site-add-scan-input">
                {renderedInputs.input}
            </div>
            {!scanning && (
                <>
                    <div key="or" className="screen-site-add-scan-or">
                        OR
                    </div>
                    <div key="button" className="screen-site-add-scan-button">
                        <ActionButton
                            key="scan"
                            disabled={loading}
                            icon="camera"
                            text="Click here to scan the QR Code"
                            onClick={showScanner}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
