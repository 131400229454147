import React, { Dispatch, useState } from "react"
import Login from "./screen/session/Login"
import ResetPassword from "./screen/session/ResetPassword"
import Signup from "./screen/session/Signup"
import { AppContext, useAppContext } from "./App"
import { SiteInformationItems } from "./util/constant"

export enum Page {
    Login = "login",
    Register = "register",
    ForgotPassword = "forgot-password"
}

export type UnauthenticatedNavigatorContext = { readonly navigateTo: (page: Page) => void }

class UnauthenticatedController {
    private _page: Page
    private setPage: Dispatch<React.SetStateAction<Page>>
    private _appContext: AppContext

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    get page(): Page {
        return this._page
    }

    get siteInformation(): SiteInformationItems {
        return this._appContext.siteInformation
    }
    readonly context: UnauthenticatedNavigatorContext = {
        navigateTo: (page: Page) => this.setPage(page)
    }

    render() {
        switch (this._page) {
            case Page.Register:
                return <Signup />
            case Page.ForgotPassword:
                return <ResetPassword />
            default:
                return <Login />
        }
    }

    public static use() {
        const appContext = useAppContext()
        const ref = React.useRef<UnauthenticatedController>()
        const [page, setPage] = useState(Page.Login)

        if (!ref.current) {
            ref.current = new UnauthenticatedController()
        }

        const controller = ref.current
        controller._page = page
        controller.setPage = setPage
        controller._appContext = appContext
        return controller
    }
}

const UnauthenticatedNavigator = React.createContext<UnauthenticatedNavigatorContext>(undefined)

export function useUnauthenticatedNavigator() {
    return React.useContext(UnauthenticatedNavigator)
}

export default function Unauthenticated() {
    const controller = UnauthenticatedController.use()

    return (
        <div className={"theme theme-login unauthenticated page page-" + controller.page}>
            <UnauthenticatedNavigator.Provider value={controller.context}>
                <section className="content centered bgImg dark">
                    <header>
                        <div className="logo-img"></div>
                        {controller.siteInformation ? controller.siteInformation?.logoText : <></>}
                    </header>
                    <div className="form">{controller.render()}</div>
                </section>
                <section className="image"></section>
            </UnauthenticatedNavigator.Provider>
        </div>
    )
}
