import React from "react"
import { FormController, FormField } from "../../../component/Form"
import { FieldValues } from "react-hook-form/dist/types"
import { FieldPath } from "react-hook-form/dist/types/path"
import { CheckKeyConstraint } from "react-hook-form/dist/types/path/common"
import ServiceProvider from "../../../service/ServiceProvider"
import { DataServiceContext } from "../../../service/data/DataService"
import User, { toUserId } from "../../../model/User"
import { SiteAccessData } from "../../../model/SiteUserAccess"
import PaginatedList, { Column, StaticDataSource } from "../../../component/PaginatedList"
import { RegisterOptions } from "react-hook-form/dist/types/validator"

type DestinationPath<TFieldValues, TTarget> = CheckKeyConstraint<TFieldValues, FieldPath<TFieldValues>, TTarget>

export type BaseFormValues = {
    userId: string
    receiveErrors: boolean
    contractRenewalNotice: boolean
}

export type FormValues = { users: BaseFormValues[] }

export type FormState = {
    notifications: Set<number>
    count: number
}

export type RenderedFields = {
    "users.${i}.userId": JSX.Element
    "users.${i}.receiveErrors": JSX.Element
    "users.${i}.contractRenewalNotice": JSX.Element
}

export type Content = {
    userAccess: { siteAccess: SiteAccessData; user: string | User }[]
}

const COLUMNS: Column[] = [
    { key: "userId", title: "User Phone / Email" },
    {
        key: "receiveErrors",
        template: "max-content",
        title: (
            <>
                Receive
                <br />
                Errors
            </>
        )
    },
    {
        key: "contractRenewalNotice",
        template: "max-content",
        title: (
            <>
                Contract
                <br />
                Renewal Notice
            </>
        )
    },
    {
        key: "delete",
        template: "max-content",
        title: null
    }
]

export function getDefaultFormState(content?: Content): FormState {
    const count = Math.max(6, content?.userAccess.length ?? 0)
    const notifications = buildActiveNotificationSet(count, undefined, content)
    return { count, notifications }
}

function buildActiveNotificationSet(
    count: number,
    formController?: FormController<FormValues>,
    content?: Content
): Set<number> {
    const notifications = new Set<number>()
    const accessList = content?.userAccess

    for (let i = 0; i < count; ++i) {
        const form1 = formController?.getValue(`users.${i}.receiveErrors`)
        const form2 = !form1 && formController?.getValue(`users.${i}.contractRenewalNotice`)
        const hasFormValue = form1 !== undefined || form2 !== undefined
        const access = accessList ? accessList[i]?.siteAccess : undefined
        const hasAccess = access !== undefined

        if (hasFormValue ? form1 || form2 : hasAccess ? access.contractRenewalNotice || access.receiveErrors : false) {
            notifications.add(i)
        }
    }

    return notifications
}

export function createFormFields<
    TFieldValues extends FieldValues = BaseFormValues,
    TDestinationPath extends DestinationPath<TFieldValues, BaseFormValues[]> = DestinationPath<
        TFieldValues,
        BaseFormValues[]
    >
>(
    sourcePaths?: FieldPath<BaseFormValues>[] | undefined,
    prefix?: TDestinationPath,
    formState?: FormState,
    setFormState?: (value: FormState) => void,
    formController?: FormController<FormValues>,
    content?: Content,
    notificationLimit?: number
): FormField<TFieldValues>[] {
    sourcePaths ??= ["userId", "receiveErrors", "contractRenewalNotice"]

    const result: FormField<TFieldValues>[] = []
    const callback = (sourcePath, itemPrefix, options) => result.push(createFormField(sourcePath, itemPrefix, options))

    processFormFields(
        sourcePaths,
        prefix,
        formState,
        setFormState,
        formController,
        content,
        callback,
        notificationLimit
    )

    return result
}

function processFormFields<
    TFieldValues extends FieldValues,
    TDestinationPath extends DestinationPath<TFieldValues, BaseFormValues[]>
>(
    sourcePaths: FieldPath<BaseFormValues>[] | undefined,
    prefix: TDestinationPath,
    formState: FormState,
    setFormState: (value: FormState) => void,
    formController: FormController<FormValues>,
    content: Content,
    callback: (
        sourcePath: FieldPath<BaseFormValues>,
        itemPrefix: DestinationPath<TFieldValues, BaseFormValues>,
        options?: RegisterOptions
    ) => void,
    notificationLimit?: number
) {
    const count = formState?.count ?? 6
    const sourcePathLen = sourcePaths.length
    const notifications = buildActiveNotificationSet(count, formController, content)
    const atNotificationLimit = notifications.size >= (notificationLimit ?? 6)

    const onChange = () => {
        const notifications = buildActiveNotificationSet(count, formController, content)
        setFormState({ ...formState, notifications })
    }

    for (let i = 0; i < count; ++i) {
        const disableNotifications = atNotificationLimit ? !notifications.has(i) : false
        const itemPrefix = `${prefix}.${i}` as DestinationPath<TFieldValues, BaseFormValues>

        for (let j = 0; j < sourcePathLen; ++j) {
            if (j > 0) {
                const options = { disabled: disableNotifications, onChange }
                callback(sourcePaths[j], itemPrefix, options)
            } else {
                callback(sourcePaths[j], itemPrefix, undefined)
            }
        }
    }
}

export function createFormField<
    TFieldValues extends FieldValues = BaseFormValues,
    TDestinationPath extends DestinationPath<TFieldValues, BaseFormValues> = DestinationPath<
        TFieldValues,
        BaseFormValues
    >
>(
    field: FieldPath<BaseFormValues>,
    prefix?: TDestinationPath,
    options?: { disabled?: boolean; onChange?: () => void }
): FormField<TFieldValues> {
    const path = (prefix !== undefined ? `${prefix}.${field}` : `${field}`) as FieldPath<TFieldValues>
    const userIdPath = (prefix !== undefined ? `${prefix}.userId` : `userId`) as FieldPath<TFieldValues>

    switch (field) {
        case "userId":
            return { name: path, title: "User Email / Phone", options }
        case "receiveErrors":
            return {
                name: path,
                input: { element: "input", props: { type: "checkbox" } },
                options: { ...options, deps: [userIdPath] }
            }
        case "contractRenewalNotice":
            return {
                name: path,
                input: { element: "input", props: { type: "checkbox" } },
                options: { ...options, deps: [userIdPath] }
            }
    }
}

export async function loadContent(context: DataServiceContext, siteId: string): Promise<Content> {
    const dataService = ServiceProvider.dataService
    const siteAccesses = await dataService.getSiteUserAccessList(context, siteId)

    return {
        userAccess: siteAccesses.map((siteAccess) => ({
            siteAccess,
            user: siteAccess.user
        }))
    }
}

export function convertContentToFormValues(content: Content): BaseFormValues[] {
    return (
        content?.userAccess.map(
            (item): BaseFormValues =>
                ({
                    userId: toUserId(item.user),
                    contractRenewalNotice: item.siteAccess.contractRenewalNotice,
                    receiveErrors: item.siteAccess.receiveErrors
                }) as BaseFormValues
        ) ?? []
    )
}

export function deleteUserRow(i: number, userCount: number, formContext: FormController<FormValues>) {
    for (++i; i < userCount; ++i) {
        formContext.setValue(`users.${i - 1}.userId`, formContext.getValue(`users.${i}.userId`))
        formContext.setValue(`users.${i - 1}.receiveErrors`, formContext.getValue(`users.${i}.receiveErrors`))
        formContext.setValue(
            `users.${i - 1}.contractRenewalNotice`,
            formContext.getValue(`users.${i}.contractRenewalNotice`)
        )
    }

    formContext.setValue(`users.${i - 1}.userId`, undefined)
    formContext.setValue(`users.${i - 1}.receiveErrors`, undefined)
    formContext.setValue(`users.${i - 1}.contractRenewalNotice`, undefined)
}

export function SiteUserFormFragment({
    renderedFields,
    formController,
    formState,
    setFormState,
    notificationLimit
}: {
    renderedFields: RenderedFields
    formController: FormController<FormValues>
    formState: FormState
    setFormState: (value: FormState) => void
    notificationLimit?: number
}) {
    const users = []
    const watchColumns = []
    notificationLimit ??= 6

    for (let i = 0; `users.${i}.userId` in renderedFields; ++i) {
        const id = i

        watchColumns.push(`users.${i}.receiveErrors`)
        watchColumns.push(`users.${i}.contractRenewalNotice`)

        users.push({
            key: `user#${i}`,
            values: [
                renderedFields[`users.${i}.userId`],
                renderedFields[`users.${i}.receiveErrors`],
                renderedFields[`users.${i}.contractRenewalNotice`],
                <button
                    type="button"
                    children="Remove"
                    onClick={() => {
                        deleteUserRow(id, formState.count, formController)
                        setFormState({ ...formState, count: formState.count - 1 })
                    }}
                />
            ]
        })
    }

    return (
        <section className="column section-users">
            <h3>Connected Users</h3>
            <div className="box">
                <PaginatedList dataSource={new StaticDataSource(users, COLUMNS)} />
                <p>Note: Maximum of {notificationLimit} notifications available in total</p>
                <button
                    type="button"
                    children="Add"
                    onClick={() => setFormState({ ...formState, count: formState.count + 1 })}
                />
            </div>
        </section>
    )
}
