import React from "react"
import { FieldOptions, FormField, BasicForm, createFormContext } from "../../component/Form"
import ServiceProvider from "../../service/ServiceProvider"
import { AppContext, useAppContext } from "../../App"
import { Page, UnauthenticatedNavigatorContext, useUnauthenticatedNavigator } from "../../Unauthenticated"

type SignupFormValues = {
    email: string
    name: string
    company: string
    phone: string
    state: string
    country: string
    password: string
    password2: string
    agreement: string
    submit: string
}

const SignupFormContext = createFormContext<SignupFormValues>()

const inputs: FormField<SignupFormValues>[] = [
    { name: "name", label: "Name", options: FieldOptions.Required },
    { name: "email", label: "Email address", options: FieldOptions.RequiredEmail },
    {
        name: "password",
        label: "Password",
        input: { element: "input", props: { type: "password" } },
        options: FieldOptions.RequiredPassword
    },
    {
        name: "password2",
        label: "Re-type password",
        input: { element: "input", props: { type: "password" } },
        relatedPasswordField: "password"
    },
    { name: "phone", label: "Phone" },
    { name: "company", label: "Company", options: FieldOptions.Required },
    {
        name: "country",
        label: "Country",
        options: FieldOptions.Required,
        input: {
            element: "select",
            options: [
                ["AU", "Australia"],
                ["NZ", "New Zealand"]
            ]
        }
    },
    {
        name: "state",
        label: "State",
        options: {
            validate: (value, values) => {
                switch (values.country) {
                    case "AU": {
                        if (value === "") return " is required for Australia"
                        else return true
                    }
                    case "NZ": {
                        if (value === "") return true
                        else return " is not valid for New Zealand"
                    }
                    default: {
                        return " requires the country field to have a value"
                    }
                }
            }
        },
        input: {
            element: "select",
            options: [
                ["", "Other / Not Applicable"],
                ["ACT", "Australian Capital Territory"],
                ["NSW", "New South Wales"],
                ["NT", "Northern Territory"],
                ["QLD", "Queensland"],
                ["SA", "South Australia"],
                ["TAS", "Tasmania"],
                ["VIC", "Victoria"],
                ["WA", "West Australia"]
            ]
        }
    },
    {
        name: "agreement",
        title: "Agreement to Terms of Use and Privacy Policy",
        label: (
            <span>
                I agree to the <a href="#terms-of-use" target="_blank" children="Terms of Use" />
                {" and "}
                <a href="https://www.dux.com.au/privacy-policy/" target="_blank" children="Privacy Policy" />
            </span>
        ),
        input: { element: "input", props: { type: "checkbox" } },
        options: FieldOptions.Required
    }
]

function signup(appContext: AppContext, data: SignupFormValues, navigator: UnauthenticatedNavigatorContext) {
    appContext.setLoading = true
    ServiceProvider.identityService
        .register(data.email, data.password, data.name, data.phone, data.company, data.state, data.country)
        .then(
            () => {
                appContext.setLoading = false
                appContext.modal.add({
                    title: "SUCCESS!",
                    content: "Your user account has been registered.",
                    close: {
                        enabled: true,
                        content: "Let's Log In",
                        callback: () => navigator.navigateTo(Page.Login)
                    }
                })
            },
            () => {
                appContext.setLoading = false
                appContext.modal.add({
                    title: "Registration Error",
                    content: "Sorry, there was an error with your registration."
                })
            }
        )
}

function showTermsOfUse(appContext: AppContext) {
    appContext.modal.add({
        title: "TERMS OF USE",
        content: (
            <>
                <p>
                    Dux Manufacturing Limited respect the privacy of information with which we are provided. This
                    Privacy Policy briefly tells you how we manage the personal information that we hold collect, use
                    and disclose. We may amend, vary or update this Privacy Policy from time to time. Any amendments,
                    variations or updates to this Privacy Policy will be posted on Dux’s website.
                </p>
                <h3>What is personal information?</h3>
                <p>
                    Personal information is any information or opinion about you that identifies you, or from which your
                    identity can be reasonably determined.
                </p>
                <h3>Why do we collect and how do we use your personal information?</h3>
                <p>
                    We may collect personal information from our customers, prospective employees, contractors, trading
                    partners and existing or possible consumers of our products and services.
                </p>
                <p>
                    We collect your personal information in order to:
                    <ul>
                        <li>
                            If you enquire about our products and services, inform you about them including providing
                            you with the relevant technical information;
                        </li>
                        <li>Determine your requirements for our products and services;</li>
                        <li>
                            Provide you with the products and services you request, provide you with new or technical
                            and other information in relation to those products and services and otherwise conduct our
                            business with you; and
                        </li>
                        <li>
                            Unless you ask us not to, to send you promotional offers and other marketing material about
                            products and services we believe may be of interest to you by mail, phone, fax email, or
                            SMS.
                        </li>
                    </ul>
                </p>
                <p>
                    In some situations we may collect some additional identity information which will enable us to
                    verify your identity (which we may do, for example, when you enter a promotion, make an inquiry over
                    the internet or request our catalogue over the telephone).
                </p>
                <p>If you do not provide your personal information, we may be unable to do one or more of the above.</p>
                <p>
                    Where it is reasonable and practicable to do so, Dux usually collects personal information directly
                    from an individual.
                </p>
                <p>
                    On occasions, your personal information may be collected from third parties, for example, credit
                    reporting agencies, medical practitioners, previous employers and those other entities to whom we
                    disclose information as set out below. When we obtain personal and other information from third
                    parties whom you refer us to, we will assume and you will ensure that you have made that third party
                    aware of your referral and the purposes of collecting, using or disclosing the relevant personal or
                    other information.
                </p>
                <h3>To whom do we disclose your personal information?</h3>
                <p>
                    Dux may disclose your personal information to:
                    <ul>
                        <li>its related companies;</li>
                        <li>
                            credit reporting agencies, ratings agencies and any business which provides information
                            about the credit worthiness of persons;
                        </li>
                        <li>
                            any entity to which we are required or authorised by law to disclose information (including
                            but not limited to the courts, Federal or State government bodies, agencies or departments,
                            government investigative or enforcement agencies);
                        </li>
                        <li>
                            our professional advisers, contractors and other service providers whom we engage from time
                            to time to carry out, or advise on, our functions and activities (including but not limited
                            to our IT consultants, mail service providers, external payment systems operators);
                        </li>
                        <li>our insurers;</li>
                        <li>the administrator of any superannuation fund of which you are a member;</li>
                        <li>your referees, including your employer;</li>
                        <li>
                            other organisations with whom we have alliances or arrangements for the purposes of
                            promoting our respective products and services;
                        </li>
                        <li>
                            other parties that Dux has advised you of at the time any personal information is collected;
                        </li>
                        <li>with your consent, any other parties; and</li>
                        <li>
                            any person to the extent necessary, in our view in order to carry out any instruction you
                            give to us.
                        </li>
                    </ul>
                </p>
                <p>
                    Your consent to the disclosure of your personal information may be given explicitly (in writing or
                    verbally) or implied (for example, if you request to be added to our mailing list).
                </p>
                <p>Ensuring your personal information is up to date</p>
                <p>
                    We rely on the personal information we hold about you to effectively conduct our business. For this
                    reason, it is important that the information collected is accurate, complete and up to date.
                </p>
                <p>
                    You can contact us at any time using the methods outlined below to update your personal information.
                </p>
                <h3>Privacy Policy Copy</h3>
                <h4>Security of personal information</h4>
                <p>
                    Personal information is mainly held in secure electronic data bases. We take precautions to
                    safeguard your personal information from loss, misuse, unauthorised access, modification or
                    disclosure.
                </p>
                <h4>Can I have access to the personal information Dux holds about me?</h4>
                <p>
                    You may request access to personal information held using the methods outlined below. There may be a
                    fee charged.
                </p>
                <p>
                    All requests for information will be handled as quickly as possible after receiving your request
                    (and payment of any requested fees).
                </p>
                <h4>Can my request for access to or updating of my personal information be denied?</h4>
                <p>
                    Dux may not always be required to provide you with access to or update your personal information. We
                    may lawfully refuse to give you access to or update your personal information in some circumstances.
                    If we refuse to give you access or update your personal information, we will provide you with the
                    reasons for our refusal.
                </p>
                <h3>Access to our products and services</h3>
                <p>
                    We understand you may have concerns about the security of personal information collected about you
                    from email inquiries. Some sites use cookies. However, any email addresses provided by you to our
                    Dux sites will not be used to track your browsing habits.
                </p>
                <h4>How to notify us of your concerns or lodge requests for access</h4>
                <p>
                    If you:
                    <ul>
                        <li>would like more information about our Privacy Policy;</li>
                        <li>have a concern about how we deal with your personal information;</li>
                        <li>wish to lodge a request for access to or updating of your personal information; or</li>
                        <li>wish to ask us not to send you promotional offers and other marketing material,</li>
                    </ul>
                </p>
                <p>
                    You can do this by Writing to the writing to the Dux Commercial Manager at PO Box 209, Moss Vale NSW
                    2577.
                </p>
            </>
        )
    })
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function showPrivacyPolicy() {}

export default function Signup() {
    const formName = "user-register"
    const appContext = useAppContext()
    const navigator = useUnauthenticatedNavigator()

    const backButton = (
        <button type="button" className="link" onClick={() => navigator.navigateTo(Page.Login)}>
            <span className="icon-img" data-icon="back" />
            <span className="icon-text">Back to Log In</span>
        </button>
    )

    function clickHandler(event: React.MouseEvent<HTMLDivElement>) {
        const href = event.target instanceof HTMLAnchorElement && event.target.getAttribute("href")
        switch (href) {
            case "#terms-of-use":
                event.preventDefault()
                showTermsOfUse(appContext)
                break
            case "#privacy-policy":
                event.preventDefault()
                showPrivacyPolicy()
                break
        }
    }

    return (
        <div id={formName + "-form-container"} className="form-container" onClick={clickHandler}>
            <h2>Let's create your account.</h2>
            <BasicForm
                context={SignupFormContext}
                name={formName}
                fields={inputs}
                submit={{ name: "submit", title: "Signup", extra: backButton }}
                onSubmit={(data: SignupFormValues) => signup(appContext, data, navigator)}
            />
        </div>
    )
}
