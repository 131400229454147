import React, { UIEvent, useMemo, useRef, useState } from "react"
import {
    createFormContext,
    FieldOptions,
    Form,
    FormController,
    FormField,
    InputForm,
    SubmitButton
} from "../../component/Form"
import ServiceProvider from "../../service/ServiceProvider"
import { Page, UnauthenticatedNavigatorContext, useUnauthenticatedNavigator } from "../../Unauthenticated"
import { AppContext, useAppContext } from "../../App"
import { Error } from "../../service/identity/IdentityService"

type ResetFormValues = {
    email: string
    haveCode: boolean
    code: string | undefined
    submit: string
}

const ResetFormContext = createFormContext<ResetFormValues>()

const Field: Record<"email" | "haveCode" | "code", FormField<ResetFormValues>> = {
    email: { name: "email", label: "Email address", options: FieldOptions.RequiredEmail },
    haveCode: {
        name: "haveCode",
        label: "I have a Verification Code",
        input: { element: "input", props: { type: "checkbox" } }
    },
    code: { name: "code", label: "Verification Code", options: FieldOptions.Required }
}

const ErrorMessages = {
    [Error.EmailNotFound]: "Sorry, a user account with the supplied email address was not found.",
    [Error.InvalidPassword]: "Sorry, the supplied verification code is not valid for this user account."
}

function resetPassword(
    appContext: AppContext,
    data: ResetFormValues,
    controller: FormController<ResetFormValues>,
    setHaveCode: React.Dispatch<boolean>,
    navigator: UnauthenticatedNavigatorContext
) {
    const errorGenerator = (title) => {
        appContext.setLoading = false
        return (errorCode?: Error) =>
            appContext.modal.add({
                title: title + " Error",
                content: ErrorMessages[errorCode] ?? "Sorry, your password could not be reset."
            })
    }

    if (!data.haveCode) {
        const modalTitle = "Password Reset"
        const onError = errorGenerator(modalTitle)
        appContext.setLoading = true
        ServiceProvider.identityService.resetPassword(data.email).then(
            (data) => {
                if (data.errorCode === Error.None) {
                    appContext.setLoading = false
                    controller.setValue("haveCode", true)
                    setHaveCode(true)

                    appContext.modal.add({
                        title: modalTitle,
                        content: "Please check your email for your verification code.",
                        onHide: () => controller.implementation.setFocus("code")
                    })
                } else {
                    onError(data.errorCode)
                }
            },
            () => onError()
        )
    } else {
        const modalTitle = "User Verification"
        const onError = errorGenerator(modalTitle)
        appContext.setLoading = true
        ServiceProvider.identityService.verify(data.email, data.code).then(
            (data) => {
                if (data.errorCode === Error.None) {
                    appContext.setLoading = false
                    appContext.modal.add({
                        title: modalTitle,
                        content: "Please check your email for your new temporary password.",
                        onHide: () => navigator.navigateTo(Page.Login)
                    })
                } else {
                    onError(data.errorCode)
                }
            },
            () => onError()
        )
    }
}

export default function ResetPassword() {
    const formName = "user-reset"
    const formController = useRef<FormController<ResetFormValues>>()
    const appContext = useAppContext()
    const navigator = useUnauthenticatedNavigator()

    const [haveCode, setHaveCode] = useState<boolean>(false)
    const haveCodeField = useMemo(
        () => ({
            ...Field.haveCode,
            options: {
                ...Field.haveCode.options,
                onChange: (e: UIEvent<HTMLInputElement>) => setHaveCode(e.currentTarget.checked)
            }
        }),
        [setHaveCode]
    )

    const backButton = (
        <button type="button" className="link" onClick={() => navigator.navigateTo(Page.Login)}>
            <span className="icon-img" data-icon="back" />
            <span className="icon-text">Back to Log In</span>
        </button>
    )

    const submitTitle = haveCode ? "Reset password" : "Send Code"
    const currentFields = haveCode ? [Field.email, haveCodeField, Field.code] : [Field.email, haveCodeField]

    return (
        <div id={formName + "-form-container"} className="form-container">
            <h2>Let's reset your password!</h2>
            <Form
                context={ResetFormContext}
                controller={formController}
                name={formName}
                onSubmit={(data: ResetFormValues) =>
                    resetPassword(appContext, data, formController.current, setHaveCode, navigator)
                }
            >
                <InputForm key="input" context={ResetFormContext} fields={currentFields} />
                <SubmitButton
                    key="submit"
                    context={ResetFormContext}
                    submit={{ name: "submit", title: submitTitle, extra: backButton }}
                />
            </Form>
            <hr />
            <div className="button-panel centered">
                <button type="button" className="link" onClick={() => navigator.navigateTo(Page.Register)}>
                    Sign Up
                </button>
            </div>
        </div>
    )
}
