import React, { FormEvent } from "react"

export interface SearchBoxProps {
    placeholder?: string
    onSearch?: (search: string) => void
}

export default function SearchBox(props: SearchBoxProps) {
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        try {
            props.onSearch(e.currentTarget.elements["search"]?.value ?? "")
        } catch (e) {
            console.error(e) // FIXME
        }
    }

    return (
        <form className="search search-box" onSubmit={onSubmit}>
            <input type="text" name="search" className="search-input" placeholder={props.placeholder ?? "Search..."} />
            <button type="button" aria-label="Search" className="search-button" />
        </form>
    )
}
