// noinspection JSUnusedGlobalSymbols
export function explodeArray<T>(input: T[], callback: (idx: number) => T): T[] {
    let idx = 0

    return input.reduce((r, a) => {
        if (idx > 0) r.push(callback(idx))
        idx++
        r.push(a)
        return r
    }, [])
}

// noinspection JSUnusedGlobalSymbols
export function explodeArrayStatic<T>(input: T[], separator: T): T[] {
    let idx = 0

    return input.reduce((r, a) => {
        if (idx++ > 0) r.push(separator)
        r.push(a)
        return r
    }, [])
}

export function splicePredicate<T>(input: T[], predicate: (T) => boolean, ...items: T[]): T[] {
    const itemLen = items.length
    const deleted = []
    let len = input.length

    for (let i = 0; i < len; ++i) {
        if (predicate(input[i])) {
            deleted.push(...input.splice(i, 1, ...items))
            len = len - 1 + itemLen
        }
    }

    return deleted
}

export function convertBase64ToByteArray(base64: string): Uint8Array {
    const binString = atob(base64)
    return Uint8Array.from(binString, (m) => m.codePointAt(0))
}
export function convertStringToByteArray(binString: string): Uint8Array {
    return Uint8Array.from(binString, (m) => m.codePointAt(0))
}