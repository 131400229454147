import React from "react"
import { FieldOptions, FormField, BasicForm, createFormContext } from "../../component/Form"
import ServiceProvider from "../../service/ServiceProvider"
import { Page, useUnauthenticatedNavigator } from "../../Unauthenticated"
import { AppContext, useAppContext } from "../../App"

type LoginFormValues = {
    email: string
    password: string
    submit: string
}

const LoginFormContext = createFormContext<LoginFormValues>()

const inputs: FormField<LoginFormValues>[] = [
    {
        name: "email",
        label: "Email address",
        input: { element: "input", props: { type: "email" } },
        options: FieldOptions.RequiredEmail
    },
    {
        name: "password",
        label: "Password",
        input: { element: "input", props: { type: "password" } },
        options: FieldOptions.Required
    }
]

function authenticate(appContext: AppContext, data: LoginFormValues) {
    appContext.setLoading = true
    ServiceProvider.identityService.authenticate(data.email, data.password).then(
        (result) => {
            appContext.setLoading = false
            if (result.authenticated) appContext.session = result.session
            else appContext.modal.add({ title: "Login Error", content: "Invalid email address and/or password" })
        },
        () => {
            appContext.setLoading = false
            appContext.modal.add({
                title: "Login Error",
                content: "Sorry, your user credentials could not be authenticated."
            })
        }
    )
}

export default function Login() {
    const formName = "user-login"
    const appContext = useAppContext()
    const navigator = useUnauthenticatedNavigator()

    const forgotPasswordButton = (
        <button type="button" className="link" onClick={() => navigator.navigateTo(Page.ForgotPassword)}>
            <span className="icon-img" data-icon="lock" />
            <span className="icon-text">Forgot Password?</span>
        </button>
    )

    return (
        <div id={formName + "-form-container"} className="form-container">
            <h2>Welcome back!</h2>
            <BasicForm
                context={LoginFormContext}
                name={formName}
                fields={inputs}
                submit={{ name: "submit", title: "Log In", extra: forgotPasswordButton }}
                onSubmit={(data: LoginFormValues) => authenticate(appContext, data)}
            />
            <hr />
            <div className="button-panel centered">
                <button type="button" className="link" onClick={() => navigator.navigateTo(Page.Register)}>
                    Sign Up
                </button>
            </div>
        </div>
    )
}
