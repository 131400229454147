import React from "react"
import { Bars } from "react-loader-spinner"

const FullScreenLoader: React.FC = () => {
    return (
        <div className="full-screen__loader">
            <Bars
                height="80"
                width="80"
                color="#ed2e24"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}

export default FullScreenLoader
