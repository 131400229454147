import React, { useCallback, useEffect, useState } from "react"
import {
    createFormContext,
    ExternalForm,
    FieldOptions,
    FormController,
    FormField,
    renderFormInputs,
    SubmitButton
} from "../../component/Form"
import { useAuthenticatedNavigator } from "../../Authenticated"
import { AppContext, useAppContext } from "../../App"
import ServiceProvider from "../../service/ServiceProvider"
import User from "../../model/User"
import Session from "../../model/Session"
import { Error, Identity } from "../../service/identity/IdentityService"
import BackButton from "../../component/BackButton"

type FormValues = {
    name: string
    email: string
    phone: string
    company: string
    state: string
    country: string
    password_current: string
    password_new: string
    password_confirm: string
    submit: string
}

const inputs: FormField<FormValues>[] = [
    { name: "name", label: "Name", options: FieldOptions.Required },
    {
        name: "email",
        label: "Email address",
        input: { element: "input", props: { type: "email" } },
        options: { ...FieldOptions.RequiredEmail, disabled: true }
    },
    { name: "phone", label: "Phone", options: FieldOptions.Required },
    { name: "company", label: "Company", options: FieldOptions.Required },
    {
        name: "state",
        label: "State",
        options: {
            ...FieldOptions.Required,
            validate: (value, values) => {
                switch (values.country) {
                    case "AU": {
                        if (value === "") return " is required for Australia"
                        else return true
                    }
                    case "NZ": {
                        if (value === "") return true
                        else return " is not valid for New Zealand"
                    }
                    default: {
                        return " requires the country field to have a value"
                    }
                }
            }
        },
        input: {
            element: "select",
            options: [
                ["", "Other / Not Applicable"],
                ["ACT", "Australian Capital Territory"],
                ["NSW", "New South Wales"],
                ["NT", "Northern Territory"],
                ["QLD", "Queensland"],
                ["SA", "South Australia"],
                ["TAS", "Tasmania"],
                ["VIC", "Victoria"],
                ["WA", "West Australia"]
            ]
        }
    },
    {
        name: "country",
        label: "Country",
        options: FieldOptions.Required,
        input: {
            element: "select",
            options: [
                ["AU", "Australia"],
                ["NZ", "New Zealand"]
            ]
        }
    },
    {
        name: "password_current",
        label: "Current Password",
        input: { element: "input", props: { type: "password" } },
        options: {
            required: false,
            validate: (value) => value === "" || FieldOptions.RequiredPassword.validate(value)
        }
    },
    {
        name: "password_new",
        label: "New Password",
        input: { element: "input", props: { type: "password" } },
        options: {
            required: false,
            validate: (value, values) => {
                if (values.password_current === "") {
                    if (value === "") return true
                    else return " can only be set if you supply the current password"
                } else {
                    return FieldOptions.RequiredPassword.validate(value)
                }
            }
        }
    },
    {
        name: "password_confirm",
        label: "Re-type new password",
        input: { element: "input", props: { type: "password" } },
        options: {
            required: false,
            validate: (value, values) => {
                if (values.password_current === "") {
                    if (value === "") return true
                    else return " can only be set if you supply the current password"
                } else if (value !== values.password_new) {
                    return " must match the new password"
                } else {
                    return true
                }
            }
        }
    }
]

function submit(appContext: AppContext, session: Session, user: User, values: FormValues) {
    const newDetails = { email: values.email, name: values.name, phone: values.phone, company: values.company }
    const passwordFlag = values.password_current?.length > 0
    const password = passwordFlag ? { current: values.password_current, new: values.password_new } : undefined
    appContext.setLoading = true
    const error = (error, msg = null) => {
        appContext.setLoading = false
        appContext.processError(error, "Update Error", msg || "Sorry, your user account could not be updated.")
    }

    ServiceProvider.identityService
        .update(session, user.id, newDetails, password)
        .then((response) => {
            appContext.setLoading = false
            if (response.errorCode === Error.None) {
                appContext.modal.add({
                    title: "SUCCESS!",
                    content: "Your user account has been updated."
                })
            } else {
                error(null, null)
            }
        })
        .catch((err) => {
            error(err)
        })
}

const FormContext = createFormContext<FormValues>()

export default function Account() {
    const navigator = useAuthenticatedNavigator()
    const appContext = useAppContext()
    const [identity, setIdentity] = useState<Identity | undefined>()
    const session = appContext.session
    const user = session.identity

    const formController: FormController<FormValues> = FormController.use({
        context: FormContext,
        name: "account",
        defaultValues: {
            email: user.email,
            company: user.company,
            phone: user.phone,
            name: user.name,
            state: user.state,
            country: user.country
        },
        onSubmit: useCallback((formValues) => submit(appContext, session, user, formValues), [session, user])
    })

    useEffect(() => {
        appContext.setLoading = true
        ServiceProvider.identityService.fetch(session).then(
            (result) => {
                formController.setValue("email", result.identity.email)
                formController.setValue("company", result.identity.company)
                formController.setValue("phone", result.identity.phone)
                formController.setValue("name", result.identity.name)
                formController.setValue("state", result.identity.state)
                formController.setValue("country", result.identity.country)
                setIdentity(result.identity)
                appContext.setLoading = false
            },
            (e) => {
                appContext.processError(e, "Profile Error", "Could not retrieve your profile data")
                appContext.setLoading = false
            }
        )
    }, [session.identity.id])

    const renderedInputs = renderFormInputs(formController, inputs)

    return (
        <FormContext.Provider value={formController}>
            <div className="form-container">
                <ExternalForm controller={formController}>
                    <div className="columns">
                        <section className="column section-account-detail">
                            <h3>Your Details</h3>

                            {identity && (
                                <div key="inputs" className="box">
                                    {renderedInputs.email}
                                    {renderedInputs.name}
                                    {renderedInputs.phone}
                                    {renderedInputs.company}
                                    {renderedInputs.state}
                                    {renderedInputs.country}
                                </div>
                            )}
                        </section>
                        <section className="column section-account-password">
                            <h3>Change Password</h3>
                            <div className="box">
                                {renderedInputs.password_current}
                                {renderedInputs.password_new}
                                {renderedInputs.password_confirm}
                            </div>
                        </section>
                    </div>
                    <div className="button-panel">
                        <BackButton />
                        <SubmitButton
                            context={FormContext}
                            wrapped={false}
                            submit={{ name: "submit", title: "Save" }}
                        />
                    </div>
                </ExternalForm>
            </div>
        </FormContext.Provider>
    )
}
