import Site, { SiteData } from "../../model/Site"
import Thing, { ThingSiteData, ThingWithSiteAccessData } from "../../model/Thing"
import SiteError from "../../model/SiteError"
import Session from "../../model/Session"
import SiteUserAccess, { SiteAccessData, UserAccessData } from "../../model/SiteUserAccess"
import User, { UserData } from "../../model/User"
import SiteOrganisation from "../../model/SiteOrganisation"
import Report from "../../model/Report"
import { DateTime } from "luxon"

export class DataServiceContext {
    public readonly controller: AbortController
    public readonly session: Session
    records: Promise<Site[]>

    constructor(session: Session, controller?: AbortController) {
        this.session = session
        this.controller = controller ?? new AbortController()
    }

    public get signal() {
        return this.controller.signal
    }
}

export class DataError extends Error {
    public readonly httpStatusCode: number | undefined

    constructor(message: string, httpStatusCode?: number) {
        super(message)
        this.httpStatusCode = httpStatusCode
    }
}

export class SessionError extends Error {
    public readonly httpStatusCode: number | undefined

    constructor(message: string, httpStatusCode?: number) {
        super(message)
        this.httpStatusCode = httpStatusCode
    }
}

export default abstract class DataService {
    abstract createThing(context: DataServiceContext, thing: ThingWithSiteAccessData): Promise<Thing>

    abstract updateSite(
        context: DataServiceContext,
        siteId: string,
        site?: SiteData,
        userAccess?: UserAccessData[],
        uploadImage?: boolean
    ): Promise<[Site | undefined, SiteUserAccess[] | undefined, undefined]>

    abstract removeSite(context: DataServiceContext, siteId: string): Promise<void>

    abstract linkUserToSites(context: DataServiceContext, user: UserData, siteAccess: SiteAccessData[]): Promise<void>

    abstract getSite(context: DataServiceContext, siteId: string): Promise<Site | undefined>

    abstract getSiteOrganisation(context: DataServiceContext, siteId: string): Promise<SiteOrganisation>

    abstract getSiteErrors(
        context: DataServiceContext,
        siteId: string,
        device?: string,
        timestampFrom?: string,
        timestampTo?: string,
        error?: string
    ): Promise<SiteError[]>

    abstract getSiteReports(context: DataServiceContext, siteId: string): Promise<Report[]>

    abstract getSiteReport(
        context: DataServiceContext,
        siteId: string,
        thingId: string,
        reportId: string,
        dateFrom?: DateTime,
        dateTo?: DateTime,
        heaterNo?: number
    ): Promise<any>

    abstract getSites(context: DataServiceContext, id?: string[], filter_by?: string, filter_value?: string): Promise<Site[]>

    abstract getSiteThings(context: DataServiceContext, siteId: string): Promise<Thing[]>

    abstract searchSites(context: DataServiceContext, search: string, filter_by?: string, filter_value?: string): Promise<Site[]>

    abstract searchSiteErrors(context: DataServiceContext, search: string): Promise<SiteError[]>

    abstract getSiteUserAccessList(context: DataServiceContext, siteId: string): Promise<SiteUserAccess[]>

    abstract getUsers(context: DataServiceContext, userIds: string[]): Promise<User[]>

    abstract lookupSerialNumber(context: DataServiceContext, input: string): Promise<ThingSiteData>
}
