import Highcharts from "highcharts-react-official"
import * as HighchartsCore from "highcharts"
import React from "react"
import { DataServiceContext } from "../../../service/data/DataService"
import { AppContext, useAppContext } from "../../../App"
import HighchartsAccessibilityModule from "highcharts/modules/accessibility"
import HighchartsExportDataModule from "highcharts/modules/export-data"
import HighchartsNoDataModule from "highcharts/modules/no-data-to-display"
import ServiceProvider from "../../../service/ServiceProvider"
import Report from "../../../model/Report"
import merge from "lodash.merge"
import saveAs from "file-saver"
import { ActionButton } from "../../../component/ActionButton"
import { useAuthenticatedNavigator } from "../../../Authenticated"
import { DateTime } from "luxon"
import { parseIntWithDefault } from "../../../util/NumberUtil"

HighchartsAccessibilityModule(HighchartsCore)
HighchartsExportDataModule(HighchartsCore)
HighchartsNoDataModule(HighchartsCore)

const DEFAULT_DURATION_MAP = {
    Daily: "24",
    Weekly: "168",
    Fortnightly: "360",
    Monthly: "744"
}

const BASE_CHART_OPTIONS: HighchartsCore.Options = {
    chart: {
        type: "line",
        height: 600
    },
    xAxis: {
        crosshair: true
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    }
}

export type SiteReportProps = {
    siteId: string
    report?: string
    defaultReport?: string
    allReports?: Report[]
}

class SiteReportController {
    private siteId: string
    private _appContext: AppContext
    private _dataServiceContext: DataServiceContext
    private _chartComponentRef: React.MutableRefObject<Highcharts.RefObject>
    private _chartComponentOptions: HighchartsCore.Options
    private _setChartComponentOptions: React.Dispatch<React.SetStateAction<HighchartsCore.Options>>
    private _activeReportId: string
    private _setActiveReportId: React.Dispatch<React.SetStateAction<string>>
    private _activeTimePeriod: number | "custom" | undefined
    private _setActiveTimePeriod: React.Dispatch<React.SetStateAction<number | "custom" | undefined>>
    private _activeDateFrom: DateTime | undefined
    private _setActiveDateFrom: React.Dispatch<React.SetStateAction<DateTime | undefined>>
    private _activeDateTo: DateTime | undefined
    private _setActiveDateTo: React.Dispatch<React.SetStateAction<DateTime | undefined>>
    private _reports: Report[]
    private _setReports: React.Dispatch<React.SetStateAction<Report[]>>

    static use(props: SiteReportProps) {
        // Setup instance
        const appContext = useAppContext()
        const navigator = useAuthenticatedNavigator()
        const ref = React.useRef<SiteReportController>()
        const chartComponentRef = React.useRef<Highcharts.RefObject>(null)
        const [reports, setReports] = React.useState<Report[]>()
        const [chartComponentOptions, setChartComponentOptions] = React.useState<HighchartsCore.Options>()
        const [activeReportId, setActiveReportId] = React.useState<string>()
        const [activeTimePeriod, setActiveTimePeriod] = React.useState<number | "custom" | undefined>()
        const [activeDateFrom, setActiveDateFrom] = React.useState<DateTime | undefined>()
        const [activeDateTo, setActiveDateTo] = React.useState<DateTime | undefined>()

        React.useEffect(() => {
            const callback = () => chartComponentRef.current?.chart.reflow()
            navigator.addSidebarMinimisedListener(callback)
            return () => navigator.removeSidebarMinimisedListener(callback)
        }, [navigator])

        // Check to see if we need to initialise a new context
        let initialise = false
        let context = ref.current

        if (!context) {
            initialise = true
            context = new SiteReportController()
            context._appContext = appContext
            context._dataServiceContext = new DataServiceContext(appContext.session)
            context._chartComponentRef = chartComponentRef
            context._setReports = setReports
            context._setActiveReportId = setActiveReportId
            context._setActiveTimePeriod = setActiveTimePeriod
            context._setActiveDateFrom = setActiveDateFrom
            context._setActiveDateTo = setActiveDateTo
            context._setChartComponentOptions = setChartComponentOptions
            ref.current = context
        }

        // Set state variables
        context._reports = reports
        context._activeReportId = activeReportId
        context._activeTimePeriod = activeTimePeriod
        context._activeDateFrom = activeDateFrom
        context._activeDateTo = activeDateTo
        context._chartComponentOptions = chartComponentOptions

        // Initialise
        if (initialise || props.siteId !== context.siteId) {
            context.initialise(props.siteId, props.report, props.defaultReport, props.allReports)
        }

        // Return instance
        return context
    }

    protected initialise(siteId: string, report?: string, defaultReportId?: string, allReports?: Report[]) {
        this.siteId = siteId
        this.reports = []
        this.clearChartData()

        if (report !== undefined) allReports = allReports.filter((current) => current.id === report)
        this.reports = allReports

        if (this._activeReportId === undefined && allReports.length > 0) {
            const defaultReport = allReports.find((report) => report.id === defaultReportId)
            const defaultOrFirstReport = defaultReport ?? allReports[0]

            this.activeReportId = defaultOrFirstReport.id

            if (this._activeTimePeriod === undefined) {
                const input = defaultOrFirstReport?.metadata?.DefaultDuration
                const mapped =
                    typeof input === "string" && Object.prototype.hasOwnProperty.call(DEFAULT_DURATION_MAP, input)
                        ? DEFAULT_DURATION_MAP[input]
                        : input

                this.activeTimePeriod = parseIntWithDefault(mapped, undefined, 24)
            }

            this.loadReportData()
        }
    }

    protected clearChartData() {
        const options = merge({}, BASE_CHART_OPTIONS)
        this._chartComponentOptions = options
        this._setChartComponentOptions(options)
        this._chartComponentRef.current?.chart?.showLoading()
    }

    protected setChartData(result: any) {
        const options = merge({}, BASE_CHART_OPTIONS, result)

        if (result.xAxis?.labels?.format !== undefined && result.tooltip?.headerFormat === undefined) {
            options.tooltip.headerFormat = options.tooltip.headerFormat.replace(
                "{point.key}",
                result.xAxis.labels.format.replace(/\{value(:.*)}/, "{point.key$1}")
            )
        }

        this._chartComponentOptions = options
        this._setChartComponentOptions(options)
        this._chartComponentRef.current?.chart?.hideLoading()
    }

    public get reports() {
        return this._reports
    }

    public set reports(reports: Report[]) {
        this._reports = reports
        this._setReports(reports)

        const activeReportId = this._activeReportId
        if (activeReportId !== undefined) {
            if (reports.findIndex((report) => report.id === activeReportId) === -1) {
                this.activeReportId = undefined
            }
        }
    }

    public get activeReportId() {
        return this._activeReportId
    }

    public set activeReportId(reportId: string) {
        if (this._activeReportId === reportId) return

        this._activeReportId = reportId
        this._setActiveReportId(reportId)
    }

    public get activeTimePeriod() {
        return this._activeTimePeriod
    }

    public set activeTimePeriod(timePeriod: string | number) {
        const time = typeof timePeriod === "string" && timePeriod !== "custom" ? parseInt(timePeriod) : timePeriod
        if (this._activeTimePeriod === time) return

        this._activeTimePeriod = time
        this._setActiveTimePeriod(time)

        if (time !== "custom") {
            this.activeDateFrom = DateTime.now().minus({ hour: time })
            this.activeDateTo = DateTime.now()
        }
    }

    public set activeDateFrom(dateFrom: string | DateTime) {
        if (typeof dateFrom === "string") dateFrom = DateTime.fromISO(dateFrom)
        this._activeDateFrom = dateFrom
        this._setActiveDateFrom(dateFrom)
    }

    public get activeDateFrom(): DateTime {
        return this._activeDateFrom
    }

    public set activeDateTo(dateTo: string | DateTime) {
        if (typeof dateTo === "string") dateTo = DateTime.fromISO(dateTo)
        this._activeDateTo = dateTo
        this._setActiveDateTo(dateTo)
    }

    public get activeDateTo(): DateTime {
        return this._activeDateTo
    }

    public get chartComponentRef(): React.MutableRefObject<Highcharts.RefObject> {
        return this._chartComponentRef
    }

    public get chartComponentOptions(): HighchartsCore.Options {
        return this._chartComponentOptions
    }

    public loadReportData() {
        const reportId = this.activeReportId
        const siteId = this.siteId

        if (!reportId || !siteId) return

        this._appContext.setLoading = true
        ServiceProvider.dataService
            .getSiteReport(
                this._dataServiceContext,
                siteId,
                undefined,
                reportId,
                this._activeDateFrom,
                this._activeDateTo
            )
            .then(
                (result) => {
                    this._appContext.setLoading = false
                    if (this._activeReportId !== reportId || this.siteId !== siteId) return
                    this.setChartData(result)
                },
                (error) => {
                    this._appContext.setLoading = false
                    this._appContext.processError(error, "Site Report", "Sorry, we could not receive report data")
                }
            )
    }

    public readonly download = () => {
        const blob = new Blob([this._chartComponentRef.current.chart.getCSV()], { type: "text/csv" })
        saveAs(blob, "data-" + this.siteId + "-" + this._activeReportId + ".csv")
    }

    public readonly onReportChangeEventHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.activeReportId = event.currentTarget.value
        this.loadReportData()
    }

    public readonly onReportTimeChangeEventHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.activeTimePeriod = event.currentTarget.value
        this.loadReportData()
    }

    public readonly onReportDateFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.activeTimePeriod = "custom"
        this.activeDateFrom = event.currentTarget.value
    }

    public readonly onReportDateToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.activeTimePeriod = "custom"
        this.activeDateTo = event.currentTarget.value
    }

    public readonly onLoad = () => {
        this.loadReportData()
    }
}

export default function SiteReport(props: SiteReportProps) {
    const context = SiteReportController.use(props)

    return (
        <div className={"box content-report"}>
            <header>
                <div className={"left"}>
                    <select onChange={context.onReportChangeEventHandler} value={context.activeReportId}>
                        {context.reports.map((report) => (
                            <option key={report.id} value={report.id} children={report.name} />
                        ))}
                    </select>
                    <select onChange={context.onReportTimeChangeEventHandler} value={context.activeTimePeriod}>
                        <option value="24">Last Day</option>
                        <option value="168">Last Week</option>
                        <option value="360">Last 15 days</option>
                        <option value="744">Last Month</option>
                        <option value="custom">Custom</option>
                    </select>
                    <input
                        type="date"
                        name="date_from"
                        onChange={context.onReportDateFromChange}
                        value={context.activeDateFrom?.toFormat("yyyy-MM-dd") ?? ""}
                    />
                    <input
                        type="date"
                        name="date_to"
                        onChange={context.onReportDateToChange}
                        value={context.activeDateTo?.toFormat("yyyy-MM-dd") ?? ""}
                    />
                    <ActionButton key="load" icon="refresh" text="Load" type="primary" onClick={context.onLoad} />
                </div>
                <div className={"right"}>
                    <ActionButton
                        key="export"
                        icon="download"
                        text="Export"
                        type="primary"
                        onClick={context.download}
                    />
                </div>
            </header>
            <Highcharts
                ref={context.chartComponentRef}
                highcharts={HighchartsCore}
                options={context.chartComponentOptions}
            />
        </div>
    )
}
