import React from "react"

export interface SiteInformationItems {
    logoText?: React.ReactNode
}

export interface SiteInformationObj {
    [key: string]: SiteInformationItems
}

export const siteInformationObj: SiteInformationObj = {
    dux: {
        logoText: <h1>BACK2BASE</h1>
    },
    thermann: {
        logoText: <></>
    }
}
