import { DateTime } from "luxon"

import IdentityService, {
    AuthenticationResult,
    Error as IdentityError,
    IdentityData,
    IdentityResult,
    RegistrationResult,
    ResetPasswordResult,
    Result
} from "./IdentityService"
import Session from "../../model/Session"
import { UserData } from "../../model/User"
import { SessionError } from "../data/DataService"

export class ApiIdentityService extends IdentityService {
    private readonly baseUrl: string

    constructor(baseUrl: string) {
        super()
        if ((baseUrl?.length ?? 0) === 0) throw new Error("Invalid base URL for ApiIdentityService")
        this.baseUrl = baseUrl.endsWith("/") ? baseUrl : baseUrl + "/"
    }

    public async update(
        session: Session,
        userId: string,
        user: UserData,
        password?: { new: string; current: string }
    ): Promise<Result> {
        let params: Record<string, string> = {
            email: userId,
            name: user.name,
            phone: user.phone,
            company: user.company,
            state: user.state,
            country_cd: user.country
        }

        if (password) {
            params = {
                ...params,
                old_password: password.current,
                new_password: password.new,
                confirm_password: password.new
            }
        }

        return await this.executeAjax(
            session,
            "PATCH",
            "auth/profile",
            params,
            ApiIdentityService.validateResult,
            (): Result => ({ errorCode: IdentityError.None }),
            ApiIdentityService.sessionError
        )
    }

    public async fetch(session: Session): Promise<IdentityResult> {
        return await this.executeAjax(
            session,
            "GET",
            "auth/profile",
            undefined,
            ApiIdentityService.validateResult,
            (result): IdentityResult => {
                const user = result.data.user

                return {
                    errorCode: IdentityError.None,
                    identity: {
                        id: user.id,
                        name: user.name,
                        email: user.email,
                        company: user.company,
                        phone: user.phone,
                        state: user.state,
                        country: user.country_cd ?? user.country
                    }
                }
            },
            ApiIdentityService.sessionError
        )
    }

    protected async doAuthenticate(email: string, password: string): Promise<AuthenticationResult> {
        if ((email?.length ?? 0) === 0 || (password?.length ?? 0) === 0) {
            return { authenticated: false, errorCode: IdentityError.InvalidParameters }
        }

        return await this.executeAjax(
            undefined,
            "POST",
            "auth/login",
            { email, password },
            (result) => ApiIdentityService.validateResult(result) && result?.data?.user !== undefined,
            (result): AuthenticationResult => {
                const user = result.data.user

                return {
                    authenticated: true,
                    errorCode: IdentityError.None,
                    session: {
                        identity: {
                            id: user.id,
                            name: user.name,
                            email: user.email,
                            company: user.company,
                            phone: user.phone,
                            state: user.state,
                            country: user.country_cd ?? user.country
                        },
                        token: user.token,
                        login: DateTime.now(),
                        expiration: DateTime.now().plus({ hours: 1 })
                    }
                }
            },
            ApiIdentityService.authenticationError
        )
    }

    protected async doLogout(session: Session): Promise<Result> {
        return await this.executeAjax(
            session,
            "POST",
            "auth/logout",
            { email: session.identity.email },
            ApiIdentityService.validateResult,
            (): Result => ({ errorCode: IdentityError.None }),
            ApiIdentityService.generalError
        )
    }

    protected async doRegister(identity: IdentityData): Promise<RegistrationResult> {
        return await this.executeAjax(
            undefined,
            "POST",
            "auth/signup",
            {
                name: identity.name,
                email: identity.email,
                user_password: identity.password,
                phone: identity.phone,
                company: identity.company,
                country_cd: identity.country,
                state: identity.state
            },
            ApiIdentityService.validateResult,
            (): RegistrationResult => ({
                registered: true,
                errorCode: IdentityError.None,
                id: identity.email
            }),
            ApiIdentityService.registrationError
        )
    }

    protected async doResetPassword(email: string): Promise<ResetPasswordResult> {
        return await this.executeAjax(
            undefined,
            "POST",
            "auth/password/forget",
            { email },
            ApiIdentityService.validateResult,
            (): ResetPasswordResult => ({ errorCode: IdentityError.None }),
            (message, error?: IdentityError) => {
                if (message === "Email Address does not exist") return { errorCode: IdentityError.EmailNotFound }
                else ApiIdentityService.generalError(message, error)
            }
        )
    }

    protected async doVerify(email: string, code: string): Promise<Result> {
        if ((email?.length ?? 0) === 0 || (code?.length ?? 0) === 0) {
            return { errorCode: IdentityError.InvalidParameters }
        }

        return await this.executeAjax(
            undefined,
            "POST",
            "auth/code/verify",
            { email, code },
            ApiIdentityService.validateResult,
            (): Result => ({ errorCode: IdentityError.None }),
            (message, error?: IdentityError) => {
                if (message === "Incorrect Code") return { errorCode: IdentityError.InvalidPassword }
                else if (message === "Email Address does not exist") return { errorCode: IdentityError.EmailNotFound }
                else ApiIdentityService.generalError(message, error)
            }
        )
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    protected async executeAjax<TResult>(
        session: Session | undefined,
        method: string,
        path: string,
        data: object,
        validateResult: (result: any) => boolean,
        onSuccess: (result: any) => TResult,
        onError: (reason: string, code?: IdentityError, statusCode?: number) => TResult
    ): Promise<TResult> {
        return await this.execute(session, method, path, data)
            .then(
                (result) => result.json(),
                () => onError("Invalid response from server")
            )
            .then(
                (result) => {
                    if (validateResult(result)) {
                        return onSuccess(result)
                    } else {
                        const message = result?.error?.message ?? "Unexpected response format from server"
                        return onError(message, IdentityError.Custom, result?.status_code)
                    }
                },
                () => onError("Internal error")
            )
    }

    protected async execute(
        session: Session | undefined,
        method: string,
        path: string,
        data: object
    ): Promise<Response> {
        const headers: Record<string, string> = {
            "Content-Type": "application/json"
        }

        if (session) {
            headers.Authorization = "Bearer " + session.token
        }

        return await fetch(this.baseUrl + path, {
            method: method,
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers,
            redirect: "error",
            referrer: "origin",
            body: JSON.stringify(data)
        })
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    protected static validateResult(result: any): boolean {
        return typeof result === "object" && result.is_success === true && result.status_code === 200
    }

    protected static authenticationError(message: string, code?: IdentityError): AuthenticationResult {
        return { ...this.generalError(message, code), authenticated: false }
    }

    protected static registrationError(message: string, code?: IdentityError): RegistrationResult {
        if (message === "User already exists") return { errorCode: IdentityError.EmailAlreadyExists, registered: false }
        else return { ...this.generalError(message, code), registered: false }
    }

    protected static sessionError(message: string, code?: IdentityError, httpStatusCode?: number): Result {
        if (httpStatusCode === 401) throw new SessionError(message, httpStatusCode)
        else return { ...this.generalError(message, code) }
    }

    protected static generalError(message: string, code?: IdentityError): Result {
        return {
            errorCode: code ?? IdentityError.Custom,
            errorDescription: message
        }
    }
}
