export default interface User {
    readonly id: string
    readonly name?: string
    readonly email?: string
    readonly state?: string
    readonly country?: string
    readonly phone?: string
    readonly company?: string
}

export type UserData = Omit<User, "id">

export function toUserId(user: string | User | UserData): string {
    if (user === undefined) return undefined
    else if (typeof user === "string") return user
    else if ("id" in user) return user.id
    else return user.email ?? user.phone
}
