import React, { CSSProperties, MouseEvent, ReactNode, useState } from "react"
import { DataServiceContext } from "../../../service/data/DataService"
import { AppContext, useAppContext } from "../../../App"
import ServiceProvider from "../../../service/ServiceProvider"
import SiteOrganisation from "../../../model/SiteOrganisation"
import { SiteErrorData } from "../../../model/SiteError"
import { explodeArray } from "../../../util/ArrayUtil"

export type OrganisationalChartProps = {
    siteId: string
}

type ErrorDialog = { style: CSSProperties; timestamp: string; error: string; fromClick: boolean }

class OrganisationalChartController {
    public readonly containerRef: React.MutableRefObject<HTMLElement> = { current: undefined }
    private siteId: string
    private _appContext: AppContext
    private _dataServiceContext: DataServiceContext
    private _data: SiteOrganisation
    private _errorDialog?: ErrorDialog
    private _setData: React.Dispatch<React.SetStateAction<SiteOrganisation>>
    private _setErrorDialog: React.Dispatch<React.SetStateAction<ErrorDialog | undefined>>

    static use(props: OrganisationalChartProps) {
        // Setup instance
        const ref = React.useRef<OrganisationalChartController>()
        const appContext = useAppContext()
        const [data, setData] = useState<SiteOrganisation>()
        const [errorDialog, setErrorDialog] = useState<ErrorDialog>()

        // Check to see if we need to initialise a new context
        let context = ref.current
        let initialise = false

        if (!context) {
            initialise = true
            context = new OrganisationalChartController()
            context._appContext = appContext
            context._dataServiceContext = new DataServiceContext(appContext.session)
            context._setData = setData
            context._setErrorDialog = setErrorDialog
            ref.current = context
        }

        // Set state variables
        context._data = data
        context._errorDialog = errorDialog
        if (initialise || props.siteId !== context.siteId) context.initialise(props.siteId)

        // Return instance
        return context
    }

    protected initialise(siteId: string) {
        this.siteId = siteId
        this._data = undefined
        this._appContext.setLoading = true
        ServiceProvider.dataService.getSiteOrganisation(this._dataServiceContext, siteId).then(
            (result) => {
                this._data = result
                this._setData(result)
                this._appContext.setLoading = false
            },
            (error) => {
                this._appContext.setLoading = false
                this._appContext.processError(error, "Site Organisation", "Could not fetch site information")
            }
        )
    }

    public get data(): SiteOrganisation | undefined {
        return this._data
    }

    public get errorDialog(): ErrorDialog | undefined {
        return this._errorDialog
    }

    public showErrorDialog(e: HTMLDivElement, fromClick: boolean) {
        const parent = this.containerRef.current
        const timestamp = e.getAttribute("data-error-timestamp")
        const error = e.getAttribute("data-error-message")
        if (!parent || timestamp === null || error === null) return

        const offset = OrganisationalChartController.getOffsetToParent(e, parent)
        const style = { top: offset.top + "px", left: offset.left + e.offsetWidth / 2 + "px" }

        const errorDialog = { style, timestamp, error, fromClick }
        this._errorDialog = errorDialog
        this._setErrorDialog(errorDialog)
    }

    private static getOffsetToParent(
        child: HTMLElement,
        parent?: HTMLElement
    ): { left: number; top: number; foundParent: boolean } {
        let left = 0
        let top = 0

        while (child && child !== parent) {
            left += child.offsetLeft
            top += child.offsetTop
            child = child.offsetParent as HTMLElement
        }

        return { left, top, foundParent: child === parent }
    }

    public hideErrorDialog() {
        this._errorDialog = undefined
        this._setErrorDialog(undefined)
    }

    public readonly onMouseStatusEnter = (e: MouseEvent<HTMLDivElement>) => {
        if (this._errorDialog) return
        this.showErrorDialog(e.currentTarget, false)
    }

    public readonly onMouseStatusLeave = () => {
        if (!this._errorDialog || this._errorDialog.fromClick) return
        this.hideErrorDialog()
    }
}

export default function OrganisationalChart(props: OrganisationalChartProps) {
    const context = OrganisationalChartController.use(props)
    const data = context.data
    if (!data) return

    const errorDialog = context.errorDialog

    return (
        <>
            <h3 id="device-information">Device Information</h3>
            <section ref={context.containerRef} className="content-organisational-chart">
                {errorDialog && (
                    <div className="object-error-container" style={errorDialog.style}>
                        <div className="object-error">
                            <div className="object-error-timestamp">{errorDialog.timestamp}</div>
                            <div className="object-error-message">{errorDialog.error}</div>
                        </div>
                    </div>
                )}
                <div className="object-container object-container-yTop object-container-xLeft">
                    <OrganisationalUnit
                        type="nau"
                        name={data.devices.nau.name}
                        description={[
                            data.devices.nau.model ? "Model: " + data.devices.nau.model : undefined,
                            data.devices.nau.serialNumber ? "S/N: " + data.devices.nau.serialNumber : undefined
                        ]}
                        items={[
                            { image: data.devices.nau.image, alt: "Image of NAU", status: data.devices.nau.status }
                        ]}
                        imageMap={data.images}
                        context={context}
                    />
                </div>
                <div className="object-container object-container-yTop object-container-xCenter">
                    <div className={data.devices.systems.length > 1 ? " object-container-multiple" : ""}>
                        {data.devices.systems.map((node, idx) => (
                            <OrganisationalUnit
                                key={idx}
                                imageMap={data.images}
                                type={"system"}
                                name={node.name}
                                description={[
                                    node.model ? "Model: " + node.model : undefined,
                                    node.serialNumber ? "S/N: " + node.serialNumber : undefined
                                ]}
                                items={[
                                    {
                                        alt: "Image of System Controller",
                                        image: node.image,
                                        status: node.status,
                                        error: node.error
                                    }
                                ]}
                                context={context}
                            />
                        ))}
                    </div>
                </div>
                {data.devices.remotes.length > 0 && (
                    <div className="object-container object-container-yTop object-container-xRight">
                        <div className={data.devices.remotes.length > 1 ? " object-container-multiple" : ""}>
                            {data.devices.remotes.map((node, idx) => (
                                <OrganisationalUnit
                                    key={idx}
                                    imageMap={data.images}
                                    type={"remote"}
                                    name={node.name}
                                    description={[
                                        node.model ? "Model: " + node.model : undefined,
                                        node.serialNumber ? "S/N: " + node.serialNumber : undefined
                                    ]}
                                    items={[
                                        {
                                            alt: "Image of Remote Controller",
                                            image: node.image,
                                            status: node.status,
                                            error: node.error
                                        }
                                    ]}
                                    context={context}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {(data.devices.heaters.length > 0 || data.devices.pumps.length > 0) && (
                    <>
                        <div
                            className={
                                "object-container object-container-yMiddle object-container-xCenter " +
                                (data.devices.pumps.length > 0 ? "object-container-custom-has-pump " : "") +
                                (data.devices.heaters.length > 0 ? "object-container-custom-has-heater " : "")
                            }
                        />
                        {data.devices.pumps.length > 0 && (
                            <div className="object-container object-container-yMiddle object-container-xRight">
                                <OrganisationalUnit
                                    type="pump"
                                    name="Pumps"
                                    items={data.devices.pumps.map((pump) => ({
                                        alt: "Image of Pump",
                                        image: pump.image,
                                        statusContent: (
                                            <>
                                                <b>{pump.hours}</b>
                                                <br />
                                                hours
                                            </>
                                        )
                                    }))}
                                    imageMap={data.images}
                                    context={context}
                                />
                            </div>
                        )}
                        {data.devices.heaters.length > 0 && (
                            <div className="object-container object-container-yBottom object-container-xFill object-container-multiple">
                                {data.devices.heaters.map((node, idx) => (
                                    <OrganisationalUnit
                                        key={idx}
                                        imageMap={data.images}
                                        type={"heater"}
                                        name={node.name}
                                        description={[
                                            node.model ? "Model: " + node.model : undefined,
                                            node.serialNumber ? "S/N: " + node.serialNumber : undefined
                                        ]}
                                        items={[
                                            {
                                                alt: "Image of Water Heater",
                                                image: node.image,
                                                status: node.status,
                                                error: node.error
                                            }
                                        ]}
                                        context={context}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}
            </section>
        </>
    )
}

function OrganisationalUnit(props: {
    context: OrganisationalChartController
    imageMap: Record<string, string>
    type: string
    name: string
    description?: string | string[]
    items: {
        alt?: string
        image: string
        status?: "ok" | "warning" | "error"
        statusContent?: ReactNode
        error?: SiteErrorData
    }[]
}) {
    const images = []
    const hasOwn = Object["hasOwn"] ?? ((object, prop): boolean => Object.prototype.hasOwnProperty.call(object, prop))
    const imageCount = props.items.length

    let description: ReactNode = undefined
    if (typeof props.description === "string") {
        description = props.description
    } else if (props.description !== undefined) {
        description = explodeArray<ReactNode>(
            props.description.filter((desc) => desc !== undefined),
            (i) => <br key={"br" + i} />
        )
    }

    for (let i = 0; i < imageCount; ++i) {
        const item = props.items[i]
        const imageUrl = hasOwn(props.imageMap, item.image) ? props.imageMap[item.image] : item.image

        images.push(
            <div key={i} className="object-image">
                <img src={imageUrl} alt={item.alt} />
                {typeof item.status === "string" ? (
                    <div
                        className={"object-status object-status-icon object-status-" + item.status}
                        onMouseEnter={props.context.onMouseStatusEnter}
                        onMouseLeave={props.context.onMouseStatusLeave}
                        data-error-timestamp={item.error?.timestamp.toLocaleString()}
                        data-error-message={item.error ? "Error Code: " + item.error.errorCode : undefined}
                    >
                        <span className="icon-img" data-icon={"site-" + item.status} />
                    </div>
                ) : item.statusContent !== undefined ? (
                    <div className="object-status object-status-text" children={item.statusContent} />
                ) : undefined}
            </div>
        )
    }

    const theme = localStorage.getItem("theme")

    const objectUnitStyles = {
        maxWidth: theme !== "dux" && props.type === "heater" && "200px",
        minWidth: theme !== "dux" && props.type === "heater" && "210px"
    }

    return (
        <div className={"object-unit object-unit-" + props.type} style={{ ...objectUnitStyles }}>
            <div className="object-images" data-images={imageCount} children={images} />
            <div className="object-info">
                <div className="object-info-name">{props.name}</div>
                {description && <div className="object-info-description">{description}</div>}
            </div>
        </div>
    )
}
