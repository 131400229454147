import React, { ReactNode } from "react"

export interface ActionButtonProps {
    key: string
    type?: "primary" | "secondary" | "link"
    title?: string
    icon?: string
    icon2?:string
    text?: ReactNode
    onClick: () => void
    selected?: boolean
    disabled?: boolean
}

export function ActionButton(button: ActionButtonProps) {
    const children = []
    const text = button.icon || button.icon2 || button.text ? button.text : button.title
    const trimmedIcon2 = button.icon2 ? button.icon2.replace(/^site-/, '') : null;

    if (button.icon) children.push(<span key="img" className="icon-img" data-icon={button.icon} />)
    if (text) children.push(<span key="text" className="icon-text" children={text} />)
    if (button.icon2) children.push(
        <div className={"site-status site-status-icon site-status-" + trimmedIcon2} style={{padding: button.icon2 ? '8px': '10px'}}>
            <span className="icon-img" data-icon={ button.icon2} style={{height: "18px", width:"18px"}} />
        </div>
    )
    const buttonStyle = {
        paddingRight: button.icon2 && '10px' ,
    };

    return (
        <button
            type="button"
            style={buttonStyle}
            className={button.type ? button.type : "link"}
            title={button.icon ? button.title : undefined}
            data-selected={button.selected === false ? "false" : button.selected === true ? "true" : null}
            children={children}
            disabled={button.disabled ?? false}
            onClick={button.onClick}
        />
    )
}
