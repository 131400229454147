import React, { Dispatch, useEffect } from "react"

const DEFAULT_MODEL_STATE = { dialogs: [], currentDialog: null, previousDialog: null }

interface ModalState {
    dialogs: Dialog[]
    currentDialog: Dialog | null
    previousDialog: Dialog | null
}

export interface Dialog {
    key?: string
    readonly title: string
    readonly className?: string
    readonly content: React.ReactNode | ((dialog: Dialog) => React.ReactNode)
    readonly close?: CloseButton
    readonly buttons?: DialogButton[]
    readonly onShow?: (dialog: Dialog) => void
    readonly onHide?: (dialog: Dialog) => void
}

export class BaseDialog implements Dialog {
    // Implementation of Dialog
    public key?: string
    public title: string
    public className?: string
    public content: React.ReactNode | ((dialog: Dialog) => React.ReactNode)
    public close: CloseButton
    public buttons: DialogButton[]
    public onShow: (dialog: Dialog) => void
    public onHide: (dialog: Dialog) => void
}

export interface DialogButton {
    key: string
    disabled?: boolean
    className: string
    content: string | React.ReactNode
    callback: (key: string, dialog: Dialog) => void
}

export type CloseButton =
    | { enabled: false }
    | {
          enabled: true
          position?: "before" | "after"
          className?: string
          content?: string | React.ReactNode
          callback?: (dialog: Dialog) => void
      }

export class ModalContext {
    private state: ModalState
    private setState: Dispatch<React.SetStateAction<ModalState>>
    private id = 0

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    static use(): ModalContext {
        // Required state variables
        const [modalState, setModalState] = React.useState<ModalState>(DEFAULT_MODEL_STATE)

        // Set up reference to context
        const ref = React.useRef<ModalContext>()

        // Check to see if we need to initialise a new context
        if (!ref.current) {
            const context = new ModalContext()
            context.state = modalState
            context.setState = setModalState
            ref.current = context
        }

        // Return instance
        return ref.current
    }

    add(dialog: Dialog) {
        const { previousDialog, dialogs } = this.state

        dialog.key ??= "#INTERNAL-KEY#" + ++this.id
        let currentDialog = this.state.currentDialog

        if (currentDialog == null) {
            currentDialog = dialog
            currentDialog.onShow?.(currentDialog)
        }

        const state = { dialogs: [...dialogs, dialog], previousDialog, currentDialog }
        this.setState(state)
        this.state = state
    }

    refresh(dialog: Dialog) {
        const state = this.state

        if (state.currentDialog === dialog) {
            this.setState({ ...state })
        }
    }

    previous(): Dialog {
        return this.state.previousDialog
    }

    current(): Dialog {
        return this.state.currentDialog
    }

    close(dialog?: Dialog) {
        let { dialogs, previousDialog, currentDialog } = this.state

        if (dialog && dialog !== currentDialog) {
            const idx = dialogs.indexOf(dialog)

            if (idx >= 0) {
                dialogs = [...dialogs]
                dialogs.splice(idx, 1)
                const state = { dialogs, previousDialog, currentDialog }
                this.setState(state)
                this.state = state
            }

            return
        }

        if (currentDialog !== null) {
            currentDialog?.onHide?.(currentDialog)
            dialogs = dialogs.slice(1)
            previousDialog = currentDialog
            currentDialog = dialogs[0] ?? null
            currentDialog?.onShow?.(currentDialog)
        }

        const state = { dialogs, previousDialog, currentDialog }
        this.setState(state)
        this.state = state
    }

    // noinspection JSUnusedGlobalSymbols
    clear() {
        let { previousDialog, currentDialog } = this.state
        const dialogs = []

        currentDialog?.onHide?.(currentDialog)
        previousDialog = currentDialog ? currentDialog : previousDialog
        currentDialog = null

        const state = { dialogs, previousDialog, currentDialog }
        this.setState(state)
        this.state = state
    }

    length() {
        return this.state.dialogs?.length ?? 0
    }
}

interface ModalProps {
    documentBody?: HTMLElement
    context?: React.MutableRefObject<ModalContext>
}

export default function Modal(props: ModalProps) {
    // Configure context
    const context = ModalContext.use()
    if (props.context) props.context.current = context

    // Prepare UI based on context
    const currentDialog = context.current()
    const renderDialog = currentDialog ?? context.previous()

    // Get document body (or top level HTML element)
    const documentBody = props.documentBody ?? document.body
    const documentBodyClassList = documentBody?.classList
    const transitionStatusClassName = currentDialog ? "show" : "fade"

    const dialogButtons = []
    const close: CloseButton = currentDialog?.close ?? { enabled: true }

    if (currentDialog) {
        for (let i = 0, len = currentDialog.buttons?.length ?? 0; i < len; ++i) {
            const button = currentDialog.buttons[i]

            dialogButtons.push(
                <button
                    key={button.key}
                    type="button"
                    disabled={button.disabled}
                    className={button.className}
                    onClick={() => button.callback(button.key, currentDialog)}
                    children={button.content}
                />
            )
        }

        if (close.enabled === true) {
            const button = (
                <button
                    key="close-button"
                    type="button"
                    className={close.className ?? "primary"}
                    children={close.content ?? "Close"}
                    onClick={() => {
                        context.close()
                        close.callback?.(currentDialog)
                    }}
                />
            )

            if (close.position === "after") {
                dialogButtons.push(button)
            } else {
                dialogButtons.unshift(button)
            }
        }
    }

    useEffect(() => {
        let keyListener = null

        if (currentDialog && close.enabled === true) {
            keyListener = (e) => {
                if (e.key === "Escape") {
                    context.close()
                    close.callback?.(currentDialog)
                }
            }
            documentBody.addEventListener("keydown", keyListener)
            documentBodyClassList?.add("modal-open")
        }

        return () => {
            documentBodyClassList?.remove("modal-open")
            if (keyListener) documentBody.removeEventListener("keydown", keyListener)
        }
    })

    const dialogContent =
        typeof renderDialog?.content === "function" ? renderDialog.content(currentDialog) : renderDialog?.content

    const renderedDialog = renderDialog ? (
        <div key={renderDialog.key} className={"modal-dialog " + (renderDialog.className ?? "")} role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">{renderDialog.title ?? ""}</h2>
                </div>
                <div className="modal-body">{dialogContent}</div>
                <div className="modal-footer">{dialogButtons}</div>
            </div>
        </div>
    ) : undefined

    return (
        <>
            <div className={"modal-backdrop " + transitionStatusClassName}></div>
            <div
                className={"modal " + transitionStatusClassName}
                role="dialog"
                aria-hidden="true"
                children={renderedDialog}
            />
        </>
    )
}
