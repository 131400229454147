import { DataServiceContext } from "../../../service/data/DataService"
import PaginatedList, {
    Column,
    DataSource,
    DataSourceRecord,
    PaginatedListContext,
    RecordFetcher
} from "../../../component/PaginatedList"
import React from "react"
import { AppContext, useAppContext } from "../../../App"
import SiteError from "../../../model/SiteError"
import ServiceProvider from "../../../service/ServiceProvider"
import { DateTime } from "luxon"
import {
    createFormContext,
    ExternalForm,
    FormController,
    FormField,
    InputForm,
    SubmitButton
} from "../../../component/Form"
import { ActionButton } from "../../../component/ActionButton"
import { explodeArray } from "../../../util/ArrayUtil"

const FilterFormContext = createFormContext<FilterFormValues>()

const fields: FormField<FilterFormValues>[] = [
    {
        name: "timestampFrom",
        label: "Date From",
        input: { element: "input", props: { type: "date" } }
    },
    {
        name: "timestampTo",
        label: "Date To",
        input: { element: "input", props: { type: "date" } }
    },
    {
        name: "error",
        label: "Error",
        input: { element: "input", props: { type: "text" } }
    }
]

type FilterFormValues = {
    timestampFrom: string
    timestampTo: string
    error: string
    submit: string
}

class SiteErrorFetcher extends RecordFetcher<SiteError, FilterFormValues> {
    static COLUMNS: Column[] = [
        { key: "timestamp", title: "Date/Time" },
        { key: "device", title: "Device" },
        { key: "error-code", title: "Code" },
        { key: "error", title: "Error" },
        { key: "checkpoint", title: "Checkpoint" }
    ]

    private readonly appContext: AppContext
    private readonly context: SiteErrorListController

    constructor(appContext: AppContext, context: SiteErrorListController) {
        super()
        this.appContext = appContext
        this.context = context
    }

    protected async execute(search: FilterFormValues, context: DataServiceContext): Promise<SiteError[]> {
        try {
            return await ServiceProvider.dataService.getSiteErrors(
                context,
                this.context.siteId,
                undefined,
                search.timestampFrom === "" ? undefined : search.timestampFrom,
                search.timestampTo === "" ? undefined : search.timestampTo,
                search.error === "" ? undefined : search.error
            )
        } catch (error) {
            if (context.controller.signal.aborted) throw error
            this.appContext.processError(error, "Site Errors", "Sorry, we could not retrieve a list of site errors")
            return Promise.resolve([])
        }
    }

    protected process(record: SiteError): DataSourceRecord {
        const lines = record.checkpoint?.split("\n")

        let checkpoint: React.ReactNode = ""
        if (lines?.length == 1) checkpoint = lines[0] // FIXME
        else if (lines?.length > 1) checkpoint = explodeArray<React.ReactNode>(lines, (i) => <br key={"br" + i} />)

        return {
            key: record.id,
            values: [
                record.timestamp.toFormat("dd/MM/yyyy HH:mm"),
                record.thingDetail ?? "",
                record.errorCode,
                record.errorDetail,
                checkpoint
            ]
        }
    }
}

export type SiteErrorListProps = {
    siteId: string
}

class SiteErrorListController {
    private _siteId: string
    private _formController: FormController<FilterFormValues>
    private _siteErrorDataSource: DataSource
    private _siteErrorContextRef: React.MutableRefObject<PaginatedListContext>

    static use(props: SiteErrorListProps) {
        // Setup instance
        const ref = React.useRef<SiteErrorListController>()
        const appContext = useAppContext()
        const siteErrorContextRef = React.useRef<PaginatedListContext>(null)
        const controller = FormController.use({
            name: "filter",
            context: FilterFormContext,
            defaultValues: {
                timestampFrom: DateTime.now().minus({ days: 7 }).toFormat("yyyy-MM-dd"),
                timestampTo: DateTime.now().toFormat("yyyy-MM-dd")
            }
        })

        // Check to see if we need to initialise a new context
        let context = ref.current
        let initialise = false

        if (!context) {
            initialise = true
            context = new SiteErrorListController()
            context._siteErrorDataSource = new SiteErrorFetcher(appContext, context).createDataSource(
                appContext.session
            )
            context._siteErrorContextRef = siteErrorContextRef
            context._formController = controller
            ref.current = context
        }

        // Initialise
        if (initialise || props.siteId !== context._siteId) context.initialise(initialise, props.siteId)

        // Return instance
        return context
    }

    protected initialise(initialise: boolean, siteId: string) {
        this._siteId = siteId
    }

    public get siteId(): string {
        return this._siteId
    }

    public get formController(): FormController<FilterFormValues> {
        return this._formController
    }

    public get siteErrorDataSource(): DataSource {
        return this._siteErrorDataSource
    }

    public get siteErrorContextRef(): React.MutableRefObject<PaginatedListContext> {
        return this._siteErrorContextRef
    }

    readonly refresh = () => {
        this._siteErrorContextRef.current?.refresh()
    }

    readonly download = () => {
        this._siteErrorContextRef.current?.download("site_errors.csv")
    }
}

export default function (props: SiteErrorListProps) {
    const context = SiteErrorListController.use(props)
    context.formController.onSubmit = (data: FilterFormValues) => context.siteErrorContextRef.current?.setFilter(data)

    return (
        <FilterFormContext.Provider value={context.formController}>
            <header>
                <h3 id="errors">Errors</h3>
                <div className="button-panel">
                    <ActionButton
                        key="refresh"
                        icon="refresh"
                        text="Refresh"
                        type="primary"
                        onClick={context.refresh}
                    />
                    <ActionButton
                        key="export"
                        icon="download"
                        text="Export"
                        type="primary"
                        onClick={context.download}
                    />
                </div>
            </header>
            <div className="box">
                <div className="form-container">
                    <h4>FILTER</h4>
                    <ExternalForm key="form" controller={context.formController}>
                        <InputForm context={FilterFormContext} fields={fields} />
                        <SubmitButton context={FilterFormContext} submit={{ name: "submit", title: "Search" }} />
                    </ExternalForm>
                </div>
                <PaginatedList
                    contextRef={context.siteErrorContextRef}
                    dataSource={context.siteErrorDataSource}
                    columns={SiteErrorFetcher.COLUMNS}
                    filter={context.formController.getValues()}
                    recordsPerPage={10}
                />
            </div>
        </FilterFormContext.Provider>
    )
}
