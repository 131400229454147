import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"

import "./static/css/index.css"
import "./static/css/font/open-sans-v34-latin-700.woff2"
import "./static/css/font/open-sans-v34-latin-regular.woff2"
import "./static/css/font/poppins-v20-latin-500.woff2"

const root = document.getElementById("root")
const theme = root.getAttribute("data-theme") as "dux" | "thermann"
localStorage.setItem("theme", theme)
ReactDOM.createRoot(root).render(<App theme={theme} />)
